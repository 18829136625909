/* ========================================================================== *\
   Utilities -> Widths ($utilities-widths)
\* ========================================================================== */

/**
 * Hat tip to @csswizardry for the fraction classes technique.
 * 
 * @link http://cbrac.co/1RvQXmS
 */

/* Auto */
.width-auto {
  width: auto !important;
}

/* Whole */
[class~="1/1"],
.one-whole {
  width: 100% !important;
}

/* Halves */
[class~="1/2"],
.one-half,
[class~="2/4"],
.two-fourths,
[class~="3/6"],
.three-sixths,
[class~="4/8"],
.four-eighths,
[class~="5/10"],
.five-tenths,
[class~="6/12"],
.six-twelfths {
  width: 50% !important;
}

/* Thirds */
[class~="1/3"],
.one-third,
[class~="2/6"],
.two-sixths,
[class~="3/9"],
.three-ninths,
[class~="4/12"],
.four-twelfths {
  width: 33.3333333% !important;
}

[class~="2/3"],
.two-thirds,
[class~="4/6"],
.four-sixths,
[class~="6/9"],
.six-ninths,
[class~="8/12"],
.eight-twelfths {
  width: 66.6666666% !important;
}

/* Fourths */
[class~="1/4"],
.one-fourth,
[class~="2/8"],
.two-eighths,
[class~="3/12"],
.three-twelfths {
  width: 25% !important;
}

[class~="3/4"],
.three-fourths,
[class~="6/8"],
.six-eighths,
[class~="9/12"],
.nine-twelfths {
  width: 75% !important;
}

/* Fifths */
[class~="1/5"],
.one-fifth,
[class~="2/10"],
.two-tenths {
  width: 20% !important;
}

[class~="2/5"],
.two-fifths,
[class~="4/10"],
.four-tenths {
  width: 40% !important;
}

[class~="3/5"],
.three-fifths,
[class~="6/10"],
.six-tenths {
  width: 60% !important;
}

[class~="4/5"],
.four-fifths,
[class~="8/10"],
.eight-tenths {
  width: 80% !important;
}

/* Sixths */
[class~="1/6"],
.one-sixth,
[class~="2/12"],
.two-twelfths {
  width: 16.6666666% !important;
}

[class~="5/6"],
.five-sixths,
[class~="10/12"],
.ten-twelfths {
  width: 83.3333333% !important;
}

/* Eighths */
[class~="1/8"],
.one-eighth {
  width: 12.5% !important;
}

[class~="3/8"],
.three-eighths {
  width: 37.5% !important;
}

[class~="5/8"],
.five-eighths {
  width: 62.5% !important;
}

[class~="7/8"],
.seven-eighths {
  width: 87.5% !important;
}

/* Ninths */
[class~="1/9"],
.one-ninth {
  width: 11.1111111% !important;
}

[class~="2/9"],
.two-ninths {
  width: 22.2222222% !important;
}

[class~="4/9"],
.four-ninths {
  width: 44.4444444% !important;
}

[class~="5/9"],
.five-ninths {
  width: 55.5555555% !important;
}

[class~="7/9"],
.seven-ninths {
  width: 77.7777777% !important;
}

[class~="8/9"],
.eight-ninths {
  width: 88.8888888% !important;
}

/* Tenths */
[class~="1/10"],
.one-tenth {
  width: 10% !important;
}

[class~="3/10"],
.three-tenths {
  width: 30% !important;
}

[class~="7/10"],
.seven-tenths {
  width: 70% !important;
}

[class~="9/10"],
.nine-tenths {
  width: 90% !important;
}

/* Twelfths */
[class~="1/12"],
.one-twelfth {
  width:  8.3333333% !important;
}

[class~="5/12"],
.five-twelfths {
  width: 41.6666666% !important;
}

[class~="7/12"],
.seven-twelfths {
  width: 58.3333333% !important;
}

[class~="11/12"],
.eleven-twelfths {
  width: 91.6666666% !important;
}

.screens({
  // Auto
  .xs-width-auto {
    width: auto !important;
  }

  // Whole
  [class~="xs-1/1"],
  .xs-one-whole {
    width: 100% !important;
  }

  // Halves
  [class~="xs-1/2"],
  .xs-one-half,
  [class~="xs-2/4"],
  .xs-two-fourths,
  [class~="xs-3/6"],
  .xs-three-sixths,
  [class~="xs-4/8"],
  .xs-four-eighths,
  [class~="xs-5/10"],
  .xs-five-tenths,
  [class~="xs-6/12"],
  .xs-six-twelfths {
    width: 50% !important;
  }

  // Thirds
  [class~="xs-1/3"],
  .xs-one-third,
  [class~="xs-2/6"],
  .xs-two-sixths,
  [class~="xs-3/9"],
  .xs-three-ninths,
  [class~="xs-4/12"],
  .xs-four-twelfths {
    width: 33.3333333% !important;
  }

  [class~="xs-2/3"],
  .xs-two-thirds,
  [class~="xs-4/6"],
  .xs-four-sixths,
  [class~="xs-6/9"],
  .xs-six-ninths,
  [class~="xs-8/12"],
  .xs-eight-twelfths {
    width: 66.6666666% !important;
  }

  // Fourths
  [class~="xs-1/4"],
  .xs-one-fourth,
  [class~="xs-2/8"],
  .xs-two-eighths,
  [class~="xs-3/12"],
  .xs-three-twelfths {
    width: 25% !important;
  }

  [class~="xs-3/4"],
  .xs-three-fourths,
  [class~="xs-6/8"],
  .xs-six-eighths,
  [class~="xs-9/12"],
  .xs-nine-twelfths {
    width: 75% !important;
  }

  // Fifths
  [class~="xs-1/5"],
  .xs-one-fifth,
  [class~="xs-2/10"],
  .xs-two-tenths {
    width: 20% !important;
  }

  [class~="xs-2/5"],
  .xs-two-fifths,
  [class~="xs-4/10"],
  .xs-four-tenths {
    width: 40% !important;
  }

  [class~="xs-3/5"],
  .xs-three-fifths,
  [class~="xs-6/10"],
  .xs-six-tenths {
    width: 60% !important;
  }

  [class~="xs-4/5"],
  .xs-four-fifths,
  [class~="xs-8/10"],
  .xs-eight-tenths {
    width: 80% !important;
  }

  // Sixths
  [class~="xs-1/6"],
  .xs-one-sixth,
  [class~="xs-2/12"],
  .xs-two-twelfths {
    width: 16.6666666% !important;
  }

  [class~="xs-5/6"],
  .xs-five-sixths,
  [class~="xs-10/12"],
  .xs-ten-twelfths {
    width: 83.3333333% !important;
  }

  // Eighths
  [class~="xs-1/8"],
  .xs-one-eighth {
    width: 12.5% !important;
  }

  [class~="xs-3/8"],
  .xs-three-eighths {
    width: 37.5% !important;
  }

  [class~="xs-5/8"],
  .xs-five-eighths {
    width: 62.5% !important;
  }

  [class~="xs-7/8"],
  .xs-seven-eighths {
    width: 87.5% !important;
  }

  // Ninths
  [class~="xs-1/9"],
  .xs-one-ninth {
    width: 11.1111111% !important;
  }

  [class~="xs-2/9"],
  .xs-two-ninths {
    width: 22.2222222% !important;
  }

  [class~="xs-4/9"],
  .xs-four-ninths {
    width: 44.4444444% !important;
  }

  [class~="xs-5/9"],
  .xs-five-ninths {
    width: 55.5555555% !important;
  }

  [class~="xs-7/9"],
  .xs-seven-ninths {
    width: 77.7777777% !important;
  }

  [class~="xs-8/9"],
  .xs-eight-ninths {
    width: 88.8888888% !important;
  }

  // Tenths
  [class~="xs-1/10"],
  .xs-one-tenth {
    width: 10% !important;
  }

  [class~="xs-3/10"],
  .xs-three-tenths {
    width: 30% !important;
  }

  [class~="xs-7/10"],
  .xs-seven-tenths {
    width: 70% !important;
  }

  [class~="xs-9/10"],
  .xs-nine-tenths {
    width: 90% !important;
  }

  // Twelfths
  [class~="xs-1/12"],
  .xs-one-twelfth {
    width:  8.3333333% !important;
  }

  [class~="xs-5/12"],
  .xs-five-twelfths {
    width: 41.6666666% !important;
  }

  [class~="xs-7/12"],
  .xs-seven-twelfths {
    width: 58.3333333% !important;
  }

  [class~="xs-11/12"],
  .xs-eleven-twelfths {
    width: 91.6666666% !important;
  }
},{
  // Auto
  .sm-width-auto {
    width: auto !important;
  }

  // Whole
  [class~="sm-1/1"],
  .sm-one-whole {
    width: 100% !important;
  }

  // Halves
  [class~="sm-1/2"],
  .sm-one-half,
  [class~="sm-2/4"],
  .sm-two-fourths,
  [class~="sm-3/6"],
  .sm-three-sixths,
  [class~="sm-4/8"],
  .sm-four-eighths,
  [class~="sm-5/10"],
  .sm-five-tenths,
  [class~="sm-6/12"],
  .sm-six-twelfths {
    width: 50% !important;
  }

  // Thirds
  [class~="sm-1/3"],
  .sm-one-third,
  [class~="sm-2/6"],
  .sm-two-sixths,
  [class~="sm-3/9"],
  .sm-three-ninths,
  [class~="sm-4/12"],
  .sm-four-twelfths {
    width: 33.3333333% !important;
  }

  [class~="sm-2/3"],
  .sm-two-thirds,
  [class~="sm-4/6"],
  .sm-four-sixths,
  [class~="sm-6/9"],
  .sm-six-ninths,
  [class~="sm-8/12"],
  .sm-eight-twelfths {
    width: 66.6666666% !important;
  }

  // Fourths
  [class~="sm-1/4"],
  .sm-one-fourth,
  [class~="sm-2/8"],
  .sm-two-eighths,
  [class~="sm-3/12"],
  .sm-three-twelfths {
    width: 25% !important;
  }

  [class~="sm-3/4"],
  .sm-three-fourths,
  [class~="sm-6/8"],
  .sm-six-eighths,
  [class~="sm-9/12"],
  .sm-nine-twelfths {
    width: 75% !important;
  }

  // Fifths
  [class~="sm-1/5"],
  .sm-one-fifth,
  [class~="sm-2/10"],
  .sm-two-tenths {
    width: 20% !important;
  }

  [class~="sm-2/5"],
  .sm-two-fifths,
  [class~="sm-4/10"],
  .sm-four-tenths {
    width: 40% !important;
  }

  [class~="sm-3/5"],
  .sm-three-fifths,
  [class~="sm-6/10"],
  .sm-six-tenths {
    width: 60% !important;
  }

  [class~="sm-4/5"],
  .sm-four-fifths,
  [class~="sm-8/10"],
  .sm-eight-tenths {
    width: 80% !important;
  }

  // Sixths
  [class~="sm-1/6"],
  .sm-one-sixth,
  [class~="sm-2/12"],
  .sm-two-twelfths {
    width: 16.6666666% !important;
  }

  [class~="sm-5/6"],
  .sm-five-sixths,
  [class~="sm-10/12"],
  .sm-ten-twelfths {
    width: 83.3333333% !important;
  }

  // Eighths
  [class~="sm-1/8"],
  .sm-one-eighth {
    width: 12.5% !important;
  }

  [class~="sm-3/8"],
  .sm-three-eighths {
    width: 37.5% !important;
  }

  [class~="sm-5/8"],
  .sm-five-eighths {
    width: 62.5% !important;
  }

  [class~="sm-7/8"],
  .sm-seven-eighths {
    width: 87.5% !important;
  }

  // Ninths
  [class~="sm-1/9"],
  .sm-one-ninth {
    width: 11.1111111% !important;
  }

  [class~="sm-2/9"],
  .sm-two-ninths {
    width: 22.2222222% !important;
  }

  [class~="sm-4/9"],
  .sm-four-ninths {
    width: 44.4444444% !important;
  }

  [class~="sm-5/9"],
  .sm-five-ninths {
    width: 55.5555555% !important;
  }

  [class~="sm-7/9"],
  .sm-seven-ninths {
    width: 77.7777777% !important;
  }

  [class~="sm-8/9"],
  .sm-eight-ninths {
    width: 88.8888888% !important;
  }

  // Tenths
  [class~="sm-1/10"],
  .sm-one-tenth {
    width: 10% !important;
  }

  [class~="sm-3/10"],
  .sm-three-tenths {
    width: 30% !important;
  }

  [class~="sm-7/10"],
  .sm-seven-tenths {
    width: 70% !important;
  }

  [class~="sm-9/10"],
  .sm-nine-tenths {
    width: 90% !important;
  }

  // Twelfths
  [class~="sm-1/12"],
  .sm-one-twelfth {
    width:  8.3333333% !important;
  }

  [class~="sm-5/12"],
  .sm-five-twelfths {
    width: 41.6666666% !important;
  }

  [class~="sm-7/12"],
  .sm-seven-twelfths {
    width: 58.3333333% !important;
  }

  [class~="sm-11/12"],
  .sm-eleven-twelfths {
    width: 91.6666666% !important;
  }
},{
  // Auto
  .md-width-auto {
    width: auto !important;
  }

  // Whole
  [class~="md-1/1"],
  .md-one-whole {
    width: 100% !important;
  }

  // Halves
  [class~="md-1/2"],
  .md-one-half,
  [class~="md-2/4"],
  .md-two-fourths,
  [class~="md-3/6"],
  .md-three-sixths,
  [class~="md-4/8"],
  .md-four-eighths,
  [class~="md-5/10"],
  .md-five-tenths,
  [class~="md-6/12"],
  .md-six-twelfths {
    width: 50% !important;
  }

  // Thirds
  [class~="md-1/3"],
  .md-one-third,
  [class~="md-2/6"],
  .md-two-sixths,
  [class~="md-3/9"],
  .md-three-ninths,
  [class~="md-4/12"],
  .md-four-twelfths {
    width: 33.3333333% !important;
  }

  [class~="md-2/3"],
  .md-two-thirds,
  [class~="md-4/6"],
  .md-four-sixths,
  [class~="md-6/9"],
  .md-six-ninths,
  [class~="md-8/12"],
  .md-eight-twelfths {
    width: 66.6666666% !important;
  }

  // Fourths
  [class~="md-1/4"],
  .md-one-fourth,
  [class~="md-2/8"],
  .md-two-eighths,
  [class~="md-3/12"],
  .md-three-twelfths {
    width: 25% !important;
  }

  [class~="md-3/4"],
  .md-three-fourths,
  [class~="md-6/8"],
  .md-six-eighths,
  [class~="md-9/12"],
  .md-nine-twelfths {
    width: 75% !important;
  }

  // Fifths
  [class~="md-1/5"],
  .md-one-fifth,
  [class~="md-2/10"],
  .md-two-tenths {
    width: 20% !important;
  }

  [class~="md-2/5"],
  .md-two-fifths,
  [class~="md-4/10"],
  .md-four-tenths {
    width: 40% !important;
  }

  [class~="md-3/5"],
  .md-three-fifths,
  [class~="md-6/10"],
  .md-six-tenths {
    width: 60% !important;
  }

  [class~="md-4/5"],
  .md-four-fifths,
  [class~="md-8/10"],
  .md-eight-tenths {
    width: 80% !important;
  }

  // Sixths
  [class~="md-1/6"],
  .md-one-sixth,
  [class~="md-2/12"],
  .md-two-twelfths {
    width: 16.6666666% !important;
  }

  [class~="md-5/6"],
  .md-five-sixths,
  [class~="md-10/12"],
  .md-ten-twelfths {
    width: 83.3333333% !important;
  }

  // Eighths
  [class~="md-1/8"],
  .md-one-eighth {
    width: 12.5% !important;
  }

  [class~="md-3/8"],
  .md-three-eighths {
    width: 37.5% !important;
  }

  [class~="md-5/8"],
  .md-five-eighths {
    width: 62.5% !important;
  }

  [class~="md-7/8"],
  .md-seven-eighths {
    width: 87.5% !important;
  }

  // Ninths
  [class~="md-1/9"],
  .md-one-ninth {
    width: 11.1111111% !important;
  }

  [class~="md-2/9"],
  .md-two-ninths {
    width: 22.2222222% !important;
  }

  [class~="md-4/9"],
  .md-four-ninths {
    width: 44.4444444% !important;
  }

  [class~="md-5/9"],
  .md-five-ninths {
    width: 55.5555555% !important;
  }

  [class~="md-7/9"],
  .md-seven-ninths {
    width: 77.7777777% !important;
  }

  [class~="md-8/9"],
  .md-eight-ninths {
    width: 88.8888888% !important;
  }

  // Tenths
  [class~="md-1/10"],
  .md-one-tenth {
    width: 10% !important;
  }

  [class~="md-3/10"],
  .md-three-tenths {
    width: 30% !important;
  }

  [class~="md-7/10"],
  .md-seven-tenths {
    width: 70% !important;
  }

  [class~="md-9/10"],
  .md-nine-tenths {
    width: 90% !important;
  }

  // Twelfths
  [class~="md-1/12"],
  .md-one-twelfth {
    width:  8.3333333% !important;
  }

  [class~="md-5/12"],
  .md-five-twelfths {
    width: 41.6666666% !important;
  }

  [class~="md-7/12"],
  .md-seven-twelfths {
    width: 58.3333333% !important;
  }

  [class~="md-11/12"],
  .md-eleven-twelfths {
    width: 91.6666666% !important;
  }
},{
  // Auto
  .lg-width-auto {
    width: auto !important;
  }

  // Whole
  [class~="lg-1/1"],
  .lg-one-whole {
    width: 100% !important;
  }

  // Halves
  [class~="lg-1/2"],
  .lg-one-half,
  [class~="lg-2/4"],
  .lg-two-fourths,
  [class~="lg-3/6"],
  .lg-three-sixths,
  [class~="lg-4/8"],
  .lg-four-eighths,
  [class~="lg-5/10"],
  .lg-five-tenths,
  [class~="lg-6/12"],
  .lg-six-twelfths {
    width: 50% !important;
  }

  // Thirds
  [class~="lg-1/3"],
  .lg-one-third,
  [class~="lg-2/6"],
  .lg-two-sixths,
  [class~="lg-3/9"],
  .lg-three-ninths,
  [class~="lg-4/12"],
  .lg-four-twelfths {
    width: 33.3333333% !important;
  }

  [class~="lg-2/3"],
  .lg-two-thirds,
  [class~="lg-4/6"],
  .lg-four-sixths,
  [class~="lg-6/9"],
  .lg-six-ninths,
  [class~="lg-8/12"],
  .lg-eight-twelfths {
    width: 66.6666666% !important;
  }

  // Fourths
  [class~="lg-1/4"],
  .lg-one-fourth,
  [class~="lg-2/8"],
  .lg-two-eighths,
  [class~="lg-3/12"],
  .lg-three-twelfths {
    width: 25% !important;
  }

  [class~="lg-3/4"],
  .lg-three-fourths,
  [class~="lg-6/8"],
  .lg-six-eighths,
  [class~="lg-9/12"],
  .lg-nine-twelfths {
    width: 75% !important;
  }

  // Fifths
  [class~="lg-1/5"],
  .lg-one-fifth,
  [class~="lg-2/10"],
  .lg-two-tenths {
    width: 20% !important;
  }

  [class~="lg-2/5"],
  .lg-two-fifths,
  [class~="lg-4/10"],
  .lg-four-tenths {
    width: 40% !important;
  }

  [class~="lg-3/5"],
  .lg-three-fifths,
  [class~="lg-6/10"],
  .lg-six-tenths {
    width: 60% !important;
  }

  [class~="lg-4/5"],
  .lg-four-fifths,
  [class~="lg-8/10"],
  .lg-eight-tenths {
    width: 80% !important;
  }

  // Sixths
  [class~="lg-1/6"],
  .lg-one-sixth,
  [class~="lg-2/12"],
  .lg-two-twelfths {
    width: 16.6666666% !important;
  }

  [class~="lg-5/6"],
  .lg-five-sixths,
  [class~="lg-10/12"],
  .lg-ten-twelfths {
    width: 83.3333333% !important;
  }

  // Eighths
  [class~="lg-1/8"],
  .lg-one-eighth {
    width: 12.5% !important;
  }

  [class~="lg-3/8"],
  .lg-three-eighths {
    width: 37.5% !important;
  }

  [class~="lg-5/8"],
  .lg-five-eighths {
    width: 62.5% !important;
  }

  [class~="lg-7/8"],
  .lg-seven-eighths {
    width: 87.5% !important;
  }

  // Ninths
  [class~="lg-1/9"],
  .lg-one-ninth {
    width: 11.1111111% !important;
  }

  [class~="lg-2/9"],
  .lg-two-ninths {
    width: 22.2222222% !important;
  }

  [class~="lg-4/9"],
  .lg-four-ninths {
    width: 44.4444444% !important;
  }

  [class~="lg-5/9"],
  .lg-five-ninths {
    width: 55.5555555% !important;
  }

  [class~="lg-7/9"],
  .lg-seven-ninths {
    width: 77.7777777% !important;
  }

  [class~="lg-8/9"],
  .lg-eight-ninths {
    width: 88.8888888% !important;
  }

  // Tenths
  [class~="lg-1/10"],
  .lg-one-tenth {
    width: 10% !important;
  }

  [class~="lg-3/10"],
  .lg-three-tenths {
    width: 30% !important;
  }

  [class~="lg-7/10"],
  .lg-seven-tenths {
    width: 70% !important;
  }

  [class~="lg-9/10"],
  .lg-nine-tenths {
    width: 90% !important;
  }

  // Twelfths
  [class~="lg-1/12"],
  .lg-one-twelfth {
    width:  8.3333333% !important;
  }

  [class~="lg-5/12"],
  .lg-five-twelfths {
    width: 41.6666666% !important;
  }

  [class~="lg-7/12"],
  .lg-seven-twelfths {
    width: 58.3333333% !important;
  }

  [class~="lg-11/12"],
  .lg-eleven-twelfths {
    width: 91.6666666% !important;
  }
},{
  // Auto
  .xl-width-auto {
    width: auto !important;
  }

  // Whole
  [class~="xl-1/1"],
  .xl-one-whole {
    width: 100% !important;
  }

  // Halves
  [class~="xl-1/2"],
  .xl-one-half,
  [class~="xl-2/4"],
  .xl-two-fourths,
  [class~="xl-3/6"],
  .xl-three-sixths,
  [class~="xl-4/8"],
  .xl-four-eighths,
  [class~="xl-5/10"],
  .xl-five-tenths,
  [class~="xl-6/12"],
  .xl-six-twelfths {
    width: 50% !important;
  }

  // Thirds
  [class~="xl-1/3"],
  .xl-one-third,
  [class~="xl-2/6"],
  .xl-two-sixths,
  [class~="xl-3/9"],
  .xl-three-ninths,
  [class~="xl-4/12"],
  .xl-four-twelfths {
    width: 33.3333333% !important;
  }

  [class~="xl-2/3"],
  .xl-two-thirds,
  [class~="xl-4/6"],
  .xl-four-sixths,
  [class~="xl-6/9"],
  .xl-six-ninths,
  [class~="xl-8/12"],
  .xl-eight-twelfths {
    width: 66.6666666% !important;
  }

  // Fourths
  [class~="xl-1/4"],
  .xl-one-fourth,
  [class~="xl-2/8"],
  .xl-two-eighths,
  [class~="xl-3/12"],
  .xl-three-twelfths {
    width: 25% !important;
  }

  [class~="xl-3/4"],
  .xl-three-fourths,
  [class~="xl-6/8"],
  .xl-six-eighths,
  [class~="xl-9/12"],
  .xl-nine-twelfths {
    width: 75% !important;
  }

  // Fifths
  [class~="xl-1/5"],
  .xl-one-fifth,
  [class~="xl-2/10"],
  .xl-two-tenths {
    width: 20% !important;
  }

  [class~="xl-2/5"],
  .xl-two-fifths,
  [class~="xl-4/10"],
  .xl-four-tenths {
    width: 40% !important;
  }

  [class~="xl-3/5"],
  .xl-three-fifths,
  [class~="xl-6/10"],
  .xl-six-tenths {
    width: 60% !important;
  }

  [class~="xl-4/5"],
  .xl-four-fifths,
  [class~="xl-8/10"],
  .xl-eight-tenths {
    width: 80% !important;
  }

  // Sixths
  [class~="xl-1/6"],
  .xl-one-sixth,
  [class~="xl-2/12"],
  .xl-two-twelfths {
    width: 16.6666666% !important;
  }

  [class~="xl-5/6"],
  .xl-five-sixths,
  [class~="xl-10/12"],
  .xl-ten-twelfths {
    width: 83.3333333% !important;
  }

  // Eighths
  [class~="xl-1/8"],
  .xl-one-eighth {
    width: 12.5% !important;
  }

  [class~="xl-3/8"],
  .xl-three-eighths {
    width: 37.5% !important;
  }

  [class~="xl-5/8"],
  .xl-five-eighths {
    width: 62.5% !important;
  }

  [class~="xl-7/8"],
  .xl-seven-eighths {
    width: 87.5% !important;
  }

  // Ninths
  [class~="xl-1/9"],
  .xl-one-ninth {
    width: 11.1111111% !important;
  }

  [class~="xl-2/9"],
  .xl-two-ninths {
    width: 22.2222222% !important;
  }

  [class~="xl-4/9"],
  .xl-four-ninths {
    width: 44.4444444% !important;
  }

  [class~="xl-5/9"],
  .xl-five-ninths {
    width: 55.5555555% !important;
  }

  [class~="xl-7/9"],
  .xl-seven-ninths {
    width: 77.7777777% !important;
  }

  [class~="xl-8/9"],
  .xl-eight-ninths {
    width: 88.8888888% !important;
  }

  // Tenths
  [class~="xl-1/10"],
  .xl-one-tenth {
    width: 10% !important;
  }

  [class~="xl-3/10"],
  .xl-three-tenths {
    width: 30% !important;
  }

  [class~="xl-7/10"],
  .xl-seven-tenths {
    width: 70% !important;
  }

  [class~="xl-9/10"],
  .xl-nine-tenths {
    width: 90% !important;
  }

  // Twelfths
  [class~="xl-1/12"],
  .xl-one-twelfth {
    width:  8.3333333% !important;
  }

  [class~="xl-5/12"],
  .xl-five-twelfths {
    width: 41.6666666% !important;
  }

  [class~="xl-7/12"],
  .xl-seven-twelfths {
    width: 58.3333333% !important;
  }

  [class~="xl-11/12"],
  .xl-eleven-twelfths {
    width: 91.6666666% !important;
  }
},{
  // Auto
  .xxl-width-auto {
    width: auto !important;
  }

  // Whole
  [class~="xxl-1/1"],
  .xxl-one-whole {
    width: 100% !important;
  }

  // Halves
  [class~="xxl-1/2"],
  .xxl-one-half,
  [class~="xxl-2/4"],
  .xxl-two-fourths,
  [class~="xxl-3/6"],
  .xxl-three-sixths,
  [class~="xxl-4/8"],
  .xxl-four-eighths,
  [class~="xxl-5/10"],
  .xxl-five-tenths,
  [class~="xxl-6/12"],
  .xxl-six-twelfths {
    width: 50% !important;
  }

  // Thirds
  [class~="xxl-1/3"],
  .xxl-one-third,
  [class~="xxl-2/6"],
  .xxl-two-sixths,
  [class~="xxl-3/9"],
  .xxl-three-ninths,
  [class~="xxl-4/12"],
  .xxl-four-twelfths {
    width: 33.3333333% !important;
  }

  [class~="xxl-2/3"],
  .xxl-two-thirds,
  [class~="xxl-4/6"],
  .xxl-four-sixths,
  [class~="xxl-6/9"],
  .xxl-six-ninths,
  [class~="xxl-8/12"],
  .xxl-eight-twelfths {
    width: 66.6666666% !important;
  }

  // Fourths
  [class~="xxl-1/4"],
  .xxl-one-fourth,
  [class~="xxl-2/8"],
  .xxl-two-eighths,
  [class~="xxl-3/12"],
  .xxl-three-twelfths {
    width: 25% !important;
  }

  [class~="xxl-3/4"],
  .xxl-three-fourths,
  [class~="xxl-6/8"],
  .xxl-six-eighths,
  [class~="xxl-9/12"],
  .xxl-nine-twelfths {
    width: 75% !important;
  }

  // Fifths
  [class~="xxl-1/5"],
  .xxl-one-fifth,
  [class~="xxl-2/10"],
  .xxl-two-tenths {
    width: 20% !important;
  }

  [class~="xxl-2/5"],
  .xxl-two-fifths,
  [class~="xxl-4/10"],
  .xxl-four-tenths {
    width: 40% !important;
  }

  [class~="xxl-3/5"],
  .xxl-three-fifths,
  [class~="xxl-6/10"],
  .xxl-six-tenths {
    width: 60% !important;
  }

  [class~="xxl-4/5"],
  .xxl-four-fifths,
  [class~="xxl-8/10"],
  .xxl-eight-tenths {
    width: 80% !important;
  }

  // Sixths
  [class~="xxl-1/6"],
  .xxl-one-sixth,
  [class~="xxl-2/12"],
  .xxl-two-twelfths {
    width: 16.6666666% !important;
  }

  [class~="xxl-5/6"],
  .xxl-five-sixths,
  [class~="xxl-10/12"],
  .xxl-ten-twelfths {
    width: 83.3333333% !important;
  }

  // Eighths
  [class~="xxl-1/8"],
  .xxl-one-eighth {
    width: 12.5% !important;
  }

  [class~="xxl-3/8"],
  .xxl-three-eighths {
    width: 37.5% !important;
  }

  [class~="xxl-5/8"],
  .xxl-five-eighths {
    width: 62.5% !important;
  }

  [class~="xxl-7/8"],
  .xxl-seven-eighths {
    width: 87.5% !important;
  }

  // Ninths
  [class~="xxl-1/9"],
  .xxl-one-ninth {
    width: 11.1111111% !important;
  }

  [class~="xxl-2/9"],
  .xxl-two-ninths {
    width: 22.2222222% !important;
  }

  [class~="xxl-4/9"],
  .xxl-four-ninths {
    width: 44.4444444% !important;
  }

  [class~="xxl-5/9"],
  .xxl-five-ninths {
    width: 55.5555555% !important;
  }

  [class~="xxl-7/9"],
  .xxl-seven-ninths {
    width: 77.7777777% !important;
  }

  [class~="xxl-8/9"],
  .xxl-eight-ninths {
    width: 88.8888888% !important;
  }

  // Tenths
  [class~="xxl-1/10"],
  .xxl-one-tenth {
    width: 10% !important;
  }

  [class~="xxl-3/10"],
  .xxl-three-tenths {
    width: 30% !important;
  }

  [class~="xxl-7/10"],
  .xxl-seven-tenths {
    width: 70% !important;
  }

  [class~="xxl-9/10"],
  .xxl-nine-tenths {
    width: 90% !important;
  }

  // Twelfths
  [class~="xxl-1/12"],
  .xxl-one-twelfth {
    width:  8.3333333% !important;
  }

  [class~="xxl-5/12"],
  .xxl-five-twelfths {
    width: 41.6666666% !important;
  }

  [class~="xxl-7/12"],
  .xxl-seven-twelfths {
    width: 58.3333333% !important;
  }

  [class~="xxl-11/12"],
  .xxl-eleven-twelfths {
    width: 91.6666666% !important;
  }
});
