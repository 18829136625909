.setting {
  margin-left: 0;
  .to-rem ( margin-bottom, 5 );

  label, input, select {
    display: inline-block;
    margin: 0;
  }

  label {
    width: 100%;

    .to-rem ( border, 1, solid #d6d6d6 );
    background-color: #f7f7f7;
    color: #737373;

    span {
      display: inline-block;
      .to-rem ( padding, 11 );
    }
  }

  .timediff {

    .timediff-control {
      float: right;

      input {
        .to-rem ( width, 62 );
        border: none;

        text-align: right;
      }

      select {
        border-top: none;
        border-right: none;
        border-bottom: none;

        border-color: #F7F7F7;
      }

    }

  }

  .int {
    float: right;
    width: 50%;
    border: none;
  }

  .string {
    float: right;
    width: 50%;
    .to-rem ( padding, 9.5 10 9.5 10 );
    border: none;
  }

  .dropdown {
    float: right;
    width: 50%;
    border: none;
  }
}
