/* ========================================================================== *\
   Utilities -> Text Alignment ($utilities-text-alignment)
\* ========================================================================== */

//
// Text alignment
//
// t = text-align
// l = left
// r = right
// c = center
// j = justify
//

.tl {
  text-align: left !important;
}

.tr {
  text-align: right !important;
}

.tc {
  text-align: center !important;
}

.tj {
  text-align: justify !important;
}

.screens({
  .xs-tl {
    text-align: left !important;
  }

  .xs-tr {
    text-align: right !important;
  }

  .xs-tc {
    text-align: center !important;
  }

  .xs-tj {
    text-align: justify !important;
  }
},{
  .sm-tl {
    text-align: left !important;
  }

  .sm-tr {
    text-align: right !important;
  }

  .sm-tc {
    text-align: center !important;
  }

  .sm-tj {
    text-align: justify !important;
  }
},{
  .md-tl {
    text-align: left !important;
  }

  .md-tr {
    text-align: right !important;
  }

  .md-tc {
    text-align: center !important;
  }

  .md-tj {
    text-align: justify !important;
  }
},{
  .lg-tl {
    text-align: left !important;
  }

  .lg-tr {
    text-align: right !important;
  }

  .lg-tc {
    text-align: center !important;
  }

  .lg-tj {
    text-align: justify !important;
  }
},{
  .xl-tl {
    text-align: left !important;
  }

  .xl-tr {
    text-align: right !important;
  }

  .xl-tc {
    text-align: center !important;
  }

  .xl-tj {
    text-align: justify !important;
  }
},{
  .xxl-tl {
    text-align: left !important;
  }

  .xxl-tr {
    text-align: right !important;
  }

  .xxl-tc {
    text-align: center !important;
  }

  .xxl-tj {
    text-align: justify !important;
  }
});
