/* ========================================================================== *\
   Utilities -> Margins ($utilities-margins)
\* ========================================================================== */

//
// m  = margin
// t  = top
// r  = right
// b  = bottom
// l  = left
// h  = horizontal
// v  = vertical
// -- = quarter
// -  = half
// +  = double
// ++ = quadruple
// 0  = none

/**
 * Hat tip to @csswizardry for the +/- technique.
 *
 * @link http://cbrac.co/1Pd1ZKl
 */

// Whole
.m {
  .to-rem(margin, @spacing-base, ~"!important");
}

.mt {
  .to-rem(margin-top, @spacing-base, ~"!important");
}

.mr {
  .to-rem(margin-right, @spacing-base, ~"!important");
}

.mb {
  .to-rem(margin-bottom, @spacing-base, ~"!important");
}

.ml {
  .to-rem(margin-left, @spacing-base, ~"!important");
}

.mh {
  .to-rem(margin-right, @spacing-base, ~"!important");
  .to-rem(margin-left, @spacing-base, ~"!important");
}

.mv {
  .to-rem(margin-top, @spacing-base, ~"!important");
  .to-rem(margin-bottom, @spacing-base, ~"!important");
}

.m0a {
  .to-rem(margin, 0 auto, ~"!important");
}

// Quarter (--)
.m-- {
  .to-rem(margin, @spacing-fourth, ~"!important");
}

.mt-- {
  .to-rem(margin-top, @spacing-fourth, ~"!important");
}

.mr-- {
  .to-rem(margin-right, @spacing-fourth, ~"!important");
}

.mb-- {
  .to-rem(margin-bottom, @spacing-fourth, ~"!important");
}

.ml-- {
  .to-rem(margin-left, @spacing-fourth, ~"!important");
}

.mh-- {
  .to-rem(margin-right, @spacing-fourth, ~"!important");
  .to-rem(margin-left, @spacing-fourth, ~"!important");
}

.mv-- {
  .to-rem(margin-top, @spacing-fourth, ~"!important");
  .to-rem(margin-bottom, @spacing-fourth, ~"!important");
}

// Half (-)
.m- {
  .to-rem(margin, @spacing-half, ~"!important");
}

.mt- {
  .to-rem(margin-top, @spacing-half, ~"!important");
}

.mr- {
  .to-rem(margin-right, @spacing-half, ~"!important");
}

.mb- {
  .to-rem(margin-bottom, @spacing-half, ~"!important");
}

.ml- {
  .to-rem(margin-left, @spacing-half, ~"!important");
}

.mh- {
  .to-rem(margin-right, @spacing-half, ~"!important");
  .to-rem(margin-left, @spacing-half, ~"!important");
}

.mv- {
  .to-rem(margin-top, @spacing-half, ~"!important");
  .to-rem(margin-bottom, @spacing-half, ~"!important");
}

// Double (+)
.m\+ {
  .to-rem(margin, @spacing-double, ~"!important");
}

.mt\+ {
  .to-rem(margin-top, @spacing-double, ~"!important");
}

.mr\+ {
  .to-rem(margin-right, @spacing-double, ~"!important");
}

.mb\+ {
  .to-rem(margin-bottom, @spacing-double, ~"!important");
}

.ml\+ {
  .to-rem(margin-left, @spacing-double, ~"!important");
}

.mh\+ {
  .to-rem(margin-right, @spacing-double, ~"!important");
  .to-rem(margin-left, @spacing-double, ~"!important");
}

.mv\+ {
  .to-rem(margin-top, @spacing-double, ~"!important");
  .to-rem(margin-bottom, @spacing-double, ~"!important");
}

// Quadruple (++)
.m\+\+ {
  .to-rem(margin, @spacing-quadruple, ~"!important");
}

.mt\+\+ {
  .to-rem(margin-top, @spacing-quadruple, ~"!important");
}

.mr\+\+ {
  .to-rem(margin-right, @spacing-quadruple, ~"!important");
}

.mb\+\+ {
  .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
}

.ml\+\+ {
  .to-rem(margin-left, @spacing-quadruple, ~"!important");
}

.mh\+\+ {
  .to-rem(margin-right, @spacing-quadruple, ~"!important");
  .to-rem(margin-left, @spacing-quadruple, ~"!important");
}

.mv\+\+ {
  .to-rem(margin-top, @spacing-quadruple, ~"!important");
  .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
}

// None (0)
.m0 {
  .to-rem(margin, @spacing-none, ~"!important");
}

.mt0 {
  .to-rem(margin-top, @spacing-none, ~"!important");
}

.mr0 {
  .to-rem(margin-right, @spacing-none, ~"!important");
}

.mb0 {
  .to-rem(margin-bottom, @spacing-none, ~"!important");
}

.ml0 {
  .to-rem(margin-left, @spacing-none, ~"!important");
}

.mh0 {
  .to-rem(margin-right, @spacing-none, ~"!important");
  .to-rem(margin-left, @spacing-none, ~"!important");
}

.mv0 {
  .to-rem(margin-top, @spacing-none, ~"!important");
  .to-rem(margin-bottom, @spacing-none, ~"!important");
}

.screens({
  // Whole
  .xs-m {
    .to-rem(margin, @spacing-base, ~"!important");
  }

  .xs-mt {
    .to-rem(margin-top, @spacing-base, ~"!important");
  }

  .xs-mr {
    .to-rem(margin-right, @spacing-base, ~"!important");
  }

  .xs-mb {
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  .xs-ml {
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .xs-mh {
    .to-rem(margin-right, @spacing-base, ~"!important");
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .xs-mv {
    .to-rem(margin-top, @spacing-base, ~"!important");
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  // Quarter (--)
  .xs-m-- {
    .to-rem(margin, @spacing-fourth, ~"!important");
  }

  .xs-mt-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
  }

  .xs-mr-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
  }

  .xs-mb-- {
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  .xs-ml-- {
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .xs-mh-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .xs-mv-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  // Half (-)
  .xs-m- {
    .to-rem(margin, @spacing-half, ~"!important");
  }

  .xs-mt- {
    .to-rem(margin-top, @spacing-half, ~"!important");
  }

  .xs-mr- {
    .to-rem(margin-right, @spacing-half, ~"!important");
  }

  .xs-mb- {
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  .xs-ml- {
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .xs-mh- {
    .to-rem(margin-right, @spacing-half, ~"!important");
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .xs-mv- {
    .to-rem(margin-top, @spacing-half, ~"!important");
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  // Double (+)
  .xs-m\+ {
    .to-rem(margin, @spacing-double, ~"!important");
  }

  .xs-mt\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
  }

  .xs-mr\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
  }

  .xs-mb\+ {
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  .xs-ml\+ {
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .xs-mh\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .xs-mv\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  // Quadruple (++)
  .xs-m\+\+ {
    .to-rem(margin, @spacing-quadruple, ~"!important");
  }

  .xs-mt\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
  }

  .xs-mr\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
  }

  .xs-mb\+\+ {
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  .xs-ml\+\+ {
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .xs-mh\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .xs-mv\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  // None (0)
  .xs-m0 {
    .to-rem(margin, @spacing-none, ~"!important");
  }

  .xs-mt0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
  }

  .xs-mr0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
  }

  .xs-mb0 {
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }

  .xs-ml0 {
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .xs-mh0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .xs-mv0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }
},{
  // Whole
  .sm-m {
    .to-rem(margin, @spacing-base, ~"!important");
  }

  .sm-mt {
    .to-rem(margin-top, @spacing-base, ~"!important");
  }

  .sm-mr {
    .to-rem(margin-right, @spacing-base, ~"!important");
  }

  .sm-mb {
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  .sm-ml {
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .sm-mh {
    .to-rem(margin-right, @spacing-base, ~"!important");
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .sm-mv {
    .to-rem(margin-top, @spacing-base, ~"!important");
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  // Quarter (--)
  .sm-m-- {
    .to-rem(margin, @spacing-fourth, ~"!important");
  }

  .sm-mt-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
  }

  .sm-mr-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
  }

  .sm-mb-- {
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  .sm-ml-- {
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .sm-mh-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .sm-mv-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  // Half (-)
  .sm-m- {
    .to-rem(margin, @spacing-half, ~"!important");
  }

  .sm-mt- {
    .to-rem(margin-top, @spacing-half, ~"!important");
  }

  .sm-mr- {
    .to-rem(margin-right, @spacing-half, ~"!important");
  }

  .sm-mb- {
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  .sm-ml- {
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .sm-mh- {
    .to-rem(margin-right, @spacing-half, ~"!important");
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .sm-mv- {
    .to-rem(margin-top, @spacing-half, ~"!important");
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  // Double (+)
  .sm-m\+ {
    .to-rem(margin, @spacing-double, ~"!important");
  }

  .sm-mt\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
  }

  .sm-mr\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
  }

  .sm-mb\+ {
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  .sm-ml\+ {
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .sm-mh\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .sm-mv\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  // Quadruple (++)
  .sm-m\+\+ {
    .to-rem(margin, @spacing-quadruple, ~"!important");
  }

  .sm-mt\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
  }

  .sm-mr\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
  }

  .sm-mb\+\+ {
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  .sm-ml\+\+ {
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .sm-mh\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .sm-mv\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  // None (0)
  .sm-m0 {
    .to-rem(margin, @spacing-none, ~"!important");
  }

  .sm-mt0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
  }

  .sm-mr0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
  }

  .sm-mb0 {
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }

  .sm-ml0 {
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .sm-mh0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .sm-mv0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }
},{
  // Whole
  .md-m {
    .to-rem(margin, @spacing-base, ~"!important");
  }

  .md-mt {
    .to-rem(margin-top, @spacing-base, ~"!important");
  }

  .md-mr {
    .to-rem(margin-right, @spacing-base, ~"!important");
  }

  .md-mb {
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  .md-ml {
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .md-mh {
    .to-rem(margin-right, @spacing-base, ~"!important");
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .md-mv {
    .to-rem(margin-top, @spacing-base, ~"!important");
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  // Quarter (--)
  .md-m-- {
    .to-rem(margin, @spacing-fourth, ~"!important");
  }

  .md-mt-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
  }

  .md-mr-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
  }

  .md-mb-- {
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  .md-ml-- {
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .md-mh-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .md-mv-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  // Half (-)
  .md-m- {
    .to-rem(margin, @spacing-half, ~"!important");
  }

  .md-mt- {
    .to-rem(margin-top, @spacing-half, ~"!important");
  }

  .md-mr- {
    .to-rem(margin-right, @spacing-half, ~"!important");
  }

  .md-mb- {
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  .md-ml- {
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .md-mh- {
    .to-rem(margin-right, @spacing-half, ~"!important");
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .md-mv- {
    .to-rem(margin-top, @spacing-half, ~"!important");
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  // Double (+)
  .md-m\+ {
    .to-rem(margin, @spacing-double, ~"!important");
  }

  .md-mt\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
  }

  .md-mr\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
  }

  .md-mb\+ {
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  .md-ml\+ {
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .md-mh\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .md-mv\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  // Quadruple (++)
  .md-m\+\+ {
    .to-rem(margin, @spacing-quadruple, ~"!important");
  }

  .md-mt\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
  }

  .md-mr\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
  }

  .md-mb\+\+ {
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  .md-ml\+\+ {
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .md-mh\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .md-mv\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  // None (0)
  .md-m0 {
    .to-rem(margin, @spacing-none, ~"!important");
  }

  .md-mt0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
  }

  .md-mr0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
  }

  .md-mb0 {
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }

  .md-ml0 {
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .md-mh0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .md-mv0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }
},{
  // Whole
  .lg-m {
    .to-rem(margin, @spacing-base, ~"!important");
  }

  .lg-mt {
    .to-rem(margin-top, @spacing-base, ~"!important");
  }

  .lg-mr {
    .to-rem(margin-right, @spacing-base, ~"!important");
  }

  .lg-mb {
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  .lg-ml {
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .lg-mh {
    .to-rem(margin-right, @spacing-base, ~"!important");
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .lg-mv {
    .to-rem(margin-top, @spacing-base, ~"!important");
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  // Quarter (--)
  .lg-m-- {
    .to-rem(margin, @spacing-fourth, ~"!important");
  }

  .lg-mt-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
  }

  .lg-mr-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
  }

  .lg-mb-- {
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  .lg-ml-- {
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .lg-mh-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .lg-mv-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  // Half (-)
  .lg-m- {
    .to-rem(margin, @spacing-half, ~"!important");
  }

  .lg-mt- {
    .to-rem(margin-top, @spacing-half, ~"!important");
  }

  .lg-mr- {
    .to-rem(margin-right, @spacing-half, ~"!important");
  }

  .lg-mb- {
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  .lg-ml- {
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .lg-mh- {
    .to-rem(margin-right, @spacing-half, ~"!important");
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .lg-mv- {
    .to-rem(margin-top, @spacing-half, ~"!important");
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  // Double (+)
  .lg-m\+ {
    .to-rem(margin, @spacing-double, ~"!important");
  }

  .lg-mt\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
  }

  .lg-mr\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
  }

  .lg-mb\+ {
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  .lg-ml\+ {
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .lg-mh\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .lg-mv\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  // Quadruple (++)
  .lg-m\+\+ {
    .to-rem(margin, @spacing-quadruple, ~"!important");
  }

  .lg-mt\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
  }

  .lg-mr\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
  }

  .lg-mb\+\+ {
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  .lg-ml\+\+ {
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .lg-mh\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .lg-mv\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  // None (0)
  .lg-m0 {
    .to-rem(margin, @spacing-none, ~"!important");
  }

  .lg-mt0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
  }

  .lg-mr0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
  }

  .lg-mb0 {
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }

  .lg-ml0 {
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .lg-mh0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .lg-mv0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }
},{
  // Whole
  .xl-m {
    .to-rem(margin, @spacing-base, ~"!important");
  }

  .xl-mt {
    .to-rem(margin-top, @spacing-base, ~"!important");
  }

  .xl-mr {
    .to-rem(margin-right, @spacing-base, ~"!important");
  }

  .xl-mb {
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  .xl-ml {
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .xl-mh {
    .to-rem(margin-right, @spacing-base, ~"!important");
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .xl-mv {
    .to-rem(margin-top, @spacing-base, ~"!important");
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  // Quarter (--)
  .xl-m-- {
    .to-rem(margin, @spacing-fourth, ~"!important");
  }

  .xl-mt-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
  }

  .xl-mr-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
  }

  .xl-mb-- {
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  .xl-ml-- {
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .xl-mh-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .xl-mv-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  // Half (-)
  .xl-m- {
    .to-rem(margin, @spacing-half, ~"!important");
  }

  .xl-mt- {
    .to-rem(margin-top, @spacing-half, ~"!important");
  }

  .xl-mr- {
    .to-rem(margin-right, @spacing-half, ~"!important");
  }

  .xl-mb- {
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  .xl-ml- {
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .xl-mh- {
    .to-rem(margin-right, @spacing-half, ~"!important");
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .xl-mv- {
    .to-rem(margin-top, @spacing-half, ~"!important");
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  // Double (+)
  .xl-m\+ {
    .to-rem(margin, @spacing-double, ~"!important");
  }

  .xl-mt\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
  }

  .xl-mr\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
  }

  .xl-mb\+ {
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  .xl-ml\+ {
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .xl-mh\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .xl-mv\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  // Quadruple (++)
  .xl-m\+\+ {
    .to-rem(margin, @spacing-quadruple, ~"!important");
  }

  .xl-mt\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
  }

  .xl-mr\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
  }

  .xl-mb\+\+ {
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  .xl-ml\+\+ {
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .xl-mh\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .xl-mv\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  // None (0)
  .xl-m0 {
    .to-rem(margin, @spacing-none, ~"!important");
  }

  .xl-mt0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
  }

  .xl-mr0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
  }

  .xl-mb0 {
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }

  .xl-ml0 {
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .xl-mh0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .xl-mv0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }
},{
  // Whole
  .xxl-m {
    .to-rem(margin, @spacing-base, ~"!important");
  }

  .xxl-mt {
    .to-rem(margin-top, @spacing-base, ~"!important");
  }

  .xxl-mr {
    .to-rem(margin-right, @spacing-base, ~"!important");
  }

  .xxl-mb {
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  .xxl-ml {
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .xxl-mh {
    .to-rem(margin-right, @spacing-base, ~"!important");
    .to-rem(margin-left, @spacing-base, ~"!important");
  }

  .xxl-mv {
    .to-rem(margin-top, @spacing-base, ~"!important");
    .to-rem(margin-bottom, @spacing-base, ~"!important");
  }

  // Quarter (--)
  .xxl-m-- {
    .to-rem(margin, @spacing-fourth, ~"!important");
  }

  .xxl-mt-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
  }

  .xxl-mr-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
  }

  .xxl-mb-- {
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  .xxl-ml-- {
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .xxl-mh-- {
    .to-rem(margin-right, @spacing-fourth, ~"!important");
    .to-rem(margin-left, @spacing-fourth, ~"!important");
  }

  .xxl-mv-- {
    .to-rem(margin-top, @spacing-fourth, ~"!important");
    .to-rem(margin-bottom, @spacing-fourth, ~"!important");
  }

  // Half (-)
  .xxl-m- {
    .to-rem(margin, @spacing-half, ~"!important");
  }

  .xxl-mt- {
    .to-rem(margin-top, @spacing-half, ~"!important");
  }

  .xxl-mr- {
    .to-rem(margin-right, @spacing-half, ~"!important");
  }

  .xxl-mb- {
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  .xxl-ml- {
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .xxl-mh- {
    .to-rem(margin-right, @spacing-half, ~"!important");
    .to-rem(margin-left, @spacing-half, ~"!important");
  }

  .xxl-mv- {
    .to-rem(margin-top, @spacing-half, ~"!important");
    .to-rem(margin-bottom, @spacing-half, ~"!important");
  }

  // Double (+)
  .xxl-m\+ {
    .to-rem(margin, @spacing-double, ~"!important");
  }

  .xxl-mt\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
  }

  .xxl-mr\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
  }

  .xxl-mb\+ {
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  .xxl-ml\+ {
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .xxl-mh\+ {
    .to-rem(margin-right, @spacing-double, ~"!important");
    .to-rem(margin-left, @spacing-double, ~"!important");
  }

  .xxl-mv\+ {
    .to-rem(margin-top, @spacing-double, ~"!important");
    .to-rem(margin-bottom, @spacing-double, ~"!important");
  }

  // Quadruple (++)
  .xxl-m\+\+ {
    .to-rem(margin, @spacing-quadruple, ~"!important");
  }

  .xxl-mt\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
  }

  .xxl-mr\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
  }

  .xxl-mb\+\+ {
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  .xxl-ml\+\+ {
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .xxl-mh\+\+ {
    .to-rem(margin-right, @spacing-quadruple, ~"!important");
    .to-rem(margin-left, @spacing-quadruple, ~"!important");
  }

  .xxl-mv\+\+ {
    .to-rem(margin-top, @spacing-quadruple, ~"!important");
    .to-rem(margin-bottom, @spacing-quadruple, ~"!important");
  }

  // None (0)
  .xxl-m0 {
    .to-rem(margin, @spacing-none, ~"!important");
  }

  .xxl-mt0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
  }

  .xxl-mr0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
  }

  .xxl-mb0 {
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }

  .xxl-ml0 {
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .xxl-mh0 {
    .to-rem(margin-right, @spacing-none, ~"!important");
    .to-rem(margin-left, @spacing-none, ~"!important");
  }

  .xxl-mv0 {
    .to-rem(margin-top, @spacing-none, ~"!important");
    .to-rem(margin-bottom, @spacing-none, ~"!important");
  }
});
