/* ========================================================================== *\
   Base -> Blockquotes ($base-blockquotes)
\* ========================================================================== */

blockquote {
  color: @blockquote-text-color;
  font-family: @blockquote-font-family;
  font-style: @blockquote-font-style;
  .to-rem(font-size, @blockquote-font-size);
  .to-rem(margin-left, @blockquote-margin-left);
  .to-rem(padding-left, @blockquote-padding-left);
  .to-rem(border-left, @blockquote-border-width, @blockquote-border-style @blockquote-border-color);

  p {
    margin: 0;
    + p {
      .to-rem(margin-top, @spacing-base);
    }
  }

  /**
   * Use <cite> inside blockquotes.
   *
   * @link http://cbrac.co/18LWCOw
   */

  cite {
    font-family: @blockquote-cite-font-family;
    font-style: @blockquote-cite-font-style;
    .to-rem(font-size, @blockquote-cite-font-size);
    color: @blockquote-cite-text-color;

    /**
     * Add an m-dash character before the <cite> tag.
     */

    &:before {
      content: "\2014 \0020";
    }

    a {
      color: @blockquote-cite-anchor-color;
    }
  }
}
