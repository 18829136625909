.assessment-player {
	.question-type {
		border-width : 3px 3px 5px 3px;
		border-style : solid;
		border-radius : 0;

		border-color : #000000;
	}

	.question-header {
		background-color : #000000;
	}

	.question-no {
		background-color : #333333;
		color : #FFFFFF;

	    float : left;
	    padding : 8px 20px 8px 8px;
	    border-radius : 0 0 30px 0;

		font-weight : bold;
	}

	.question {
		background-color : #000000;
		color : #FFFFFF;

		padding : 8px;
		overflow : hidden;

		font-weight : bold;
	}

	.question h1, .question h2, .question h3, .question h4, .question h5, .question h6, .question h7, .question p {
		margin : 0;
	}

	.question-body {
		padding : 10px;
		overflow : hidden;
	}

	.question-type {
		display: none;

		.correct-feedback-icon {
			display: none;
		}

		&.question-answered-correctly {
			.correct-feedback-icon {
				display: block;
				color: #15A362;
			}
		}

		.incorrect-feedback-icon {
			display: none;
		}

		&.question-answered-incorrectly {
			.incorrect-feedback-icon {
				display: block;
				color: #A31515;
			}
		}
	}

	.answers ul {
		list-style : none;
		padding-left : 10px;
	}

	.assessment-btns {
		float : right;
	}

	input[type="button"].asmt {
		cursor : pointer;
		border-width : 1px 1px 3px 1px;
		border-style : solid;
		border-radius : 0;
		padding : 6px;

		font-size : 14px;
		font-weight : bold;
	}

	.view-extra-info-btn {
		border-color : #D57E00;
		background-color : #FB900A;
		color : #FFFFFF;

		float : left;
	}

	.submit-btn {
		border-color : #04862F;
		background-color : #2BCD5D;
		color : #FFFFFF;
	}

	.next-question-btn {
		border-color : #04862F;
		background-color : #2BCD5D;
		color : #FFFFFF;
	}

	.finish-quiz-btn {
		border-color : #04862F;
		background-color : #2BCD5D;
		color : #FFFFFF;
	}

	.question-answered-correctly {
		border-color : #15A362;
		background : #ffffff;
		background : -moz-linear-gradient(left,  #ffffff 0%, #dff7eb 100%);
		background : -webkit-gradient(linear, left top, right top, color-stop(0%,#ffffff), color-stop(100%,#dff7eb));
		background : -webkit-linear-gradient(left,  #ffffff 0%,#dff7eb 100%);
		background : -o-linear-gradient(left,  #ffffff 0%,#dff7eb 100%);
		background : -ms-linear-gradient(left,  #ffffff 0%,#dff7eb 100%);
		background : linear-gradient(to right,  #ffffff 0%,#dff7eb 100%);
	 }
	.question-answered-correctly .question { background-color : #15A362; }
	.question-answered-correctly .question-header { background-color : #15A362; }
	.question-answered-correctly .question-no { background-color : #1FB671; }

	.question-answered-incorrectly {
		border-color : #A31515;
		background : #ffffff;
		background : -moz-linear-gradient(left, #ffffff 0%, #f7dfdf 100%);
		background : -webkit-linear-gradient(left, #ffffff 0%, #f7dfdf 100%);
		background : -webkit-gradient(linear, 0 0, 100% 0, from(#ffffff), to(#f7dfdf));
		background : -o-linear-gradient(left, #ffffff 0%, #f7dfdf 100%);
		background : linear-gradient(left, #ffffff 0%, #f7dfdf 100%);
	}
	.question-answered-incorrectly .question { background-color : #A31515; }
	.question-answered-incorrectly .question-header { background-color : #A31515; }
	.question-answered-incorrectly .question-no { background-color : #C02323; }

	#extra-info {
		border-color : #000000;
		background-color : #FFFFFF;
		color : #000000;

		border-radius : 0;
		box-shadow : 5px 5px 3px rgba(0, 0, 0, 0.40);
		border-width : 3px 3px 5px 3px;
		border-style : solid;
	}

	#extra-info-header {
		background-color : #000000;
		color : #FFFFFF;

		padding : 8px;
	}

	#extra-info-content {
		padding : 25px;

		font-size : 18px;
	}

	#extra-info-close-btn {
		cursor : pointer;
	}

	#extra-info-close-btn:hover {
		text-decoration : underline;
	}
	/* MULTIPLE CHOICE */

	#multiple-choice .answers li {
		cursor : pointer;
		margin : 10px 0;
	}

	#multiple-choice .answers li label {
		cursor : pointer;
		padding : 0 0 0 10px;
	}

	/* / MULTIPLE CHOICE */

	/* DRAG IN THE BLANK */

	.drag-in-the-blank {
		position: relative;
	}

	.ditb-statement {
		.to-rem ( line-height, 22 );

		.ditb-empty {
			display: inline-block;
			position: relative;
			.to-rem ( width, 100 );
			.to-rem ( height, 22 );
			.to-rem ( top, 3 );
			.to-rem ( border-radius, 5 );

			.to-rem ( border, 1, dashed #81C781 );
			background-color: #E2F9BF;

			text-align: center;
		}
	}

	.ditb-answer {
		display: inline-block;
		.to-rem ( padding, 2 );
		.to-rem ( border-radius, 5 );

		.to-rem ( border, 1, dashed #81C781 );
		background-color: #E2F9BF;

		color: #5CB54D;

		text-align: center;

		cursor: pointer;
	}

	.no-select {
		-webkit-user-select: none; /* Chrome/Safari */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+ */

		/* Rules below not implemented in browsers yet */
		-o-user-select: none;
		user-select: none;
	}

	.draggable {
		.no-select;
	}

	/* / DRAG IN THE BLANK */

	/* FILL IN THE BLANK */

	.fitb-statement {
		.to-rem ( line-height, 22 );

		li {
			.to-rem ( margin, 2 0 );
		}

		p {
			.to-rem ( margin, 2 0 );
		}

		.fitb-input {
			.to-rem ( margin-left, 8 );
			padding: 0;
			border-style: none;

		  background: none;
		  color: #5CB54D;

			.to-rem ( font-size, 13 );
		}
	}

	.fitb-answer {
		display: inline-block;
		.to-rem ( padding, 2 );
		.to-rem ( border-radius, 5 );

		.to-rem ( border, 1, dashed #81C781 );
		background-color: #E2F9BF;

		color: #5CB54D;

		text-align: center;

		cursor: pointer;
	}

	/* / FILL IN THE BLANK */

	/* DRAG AND DROP */

	.drag-and-drop {
		position: relative;
		.to-rem ( margin-top, 5 );

		.dad-categories {
			width: 60%;
			float: left;

			.dad-player-category {
				width: 49%;
				.to-rem ( padding, 10 );
				.to-rem ( margin-top, 5 );
				margin-left: 1%;

				.to-rem ( border-width, 1 1 4 1);
				border-style: solid;
				border-color: #EAEAEA;

				background-color: @color-white;

				&:nth-child(2n) {
					float: right;
				}

				&:nth-child(2n+1) {
					float: left;
				}

				.category-items-host {
					.to-rem ( min-height, 30 );
					.to-rem ( padding, 5 );

					.to-rem ( border, 1, dashed #EAEAEA );

					header {
						color: #C00;

						text-align: center;
					}

					.dad-added-item {
						.to-rem ( padding, 7 );
				    .to-rem ( margin, 3 );
				    .to-rem ( border-radius, 3 );
				    .to-rem ( border, 1, solid #EEEEEE );
				    .to-rem ( font-size, 12 );

						.dad-added-item-cross {
							float: right;
							cursor: pointer;
							color: @color-black;

							&:hover {
								color: #C00;
							}
						}
					}
				}

			}
		}

		.answers {
			display: flex;
			float: left;
			width: 40%;
			align-items: center;
	    justify-content: center;
			.to-rem ( margin-top, 5 );
	    .to-rem ( height, 95 );
		}

		.dad-player-item {
			.to-rem ( border, 1, solid #900 );
	    .to-rem ( padding, 10 );
	    .to-rem ( width, 280 );
			margin-left: 0;


	    background-color: #C00;
	    color: #fff;

			cursor: pointer;
		}
	}

	/* / DRAG AND DROP */

	/* SUMMARY */

	#summary {
		width : 895px;
		border-width : 3px 3px 5px 3px;
		border-style : solid;
		border-radius : 0;
		display : none;

		border-color : #000000;
	}

	.summary-header {
		background-color : #000000;

		width : 100%;
	}

	.summary-header .header-decoration {
		background : #333333;
		color : #FFFFFF;

	    float : left;
	    padding : 8px 20px 8px 8px;
	    border-radius : 0 0 30px 0;
	    font-weight : bold;
	}

	.summary-body {
		height : 260px;
	  padding : 40px;
		overflow : scroll;
		overflow-x : hidden;
	}

	.summary-q-status { text-align : center; }

	.summary-headline {
	  font-size : 28px;
	  font-weight : bold;
	  font-family : Calibri;
		text-align : center;
	}

	.summary-headline.passed {
		color : #44BB60;
	}

	.summary-headline.failed {
		color : #C80104;
	}

	.percentageBarHost {
		margin : 50px auto;
		width : 400px;

		color : #000000;

		font-size : 18px;
	    font-weight : bold;
	    font-style : italic;
	}

	.percentageBarBG {
		position : relative;
		width : 400px;
		height : 50px;
		padding : 2px 4px 2px 3px;
		border-style : solid;
		border-width : 8px;

	    background : #FFFFFF;
		border-color : #000000;
	}

	.percentageBar {
		width : 0;
	    height : 28px;
	    padding : 6px 0 10px 0;
		border-style : solid none;
		border-width : 1px 0 6px 0;

	    background : #44BB44;
	    border-top-color : #66CC66;
	    border-bottom-color : #339933;
		color : #FFFFFF;

	    text-align : center;
	    font-weight : bold;
	    font-size : 20px;
	    font-style : italic;
	}

	.percentageNumber {
		position : absolute;
	    padding : 9px 0;
	    width : 400px;

		color : #006600;

	    font-size : 26px;
	    font-weight : bold;
	    font-style : italic;
	    text-align : center;
	}

	.summaryMoveOnMessage {
		text-align : center;
	    font-size : 22px;
	    font-weight : bold;
	    font-family : Calibri;
	}

	.assmt-summary-question-list {
		border-style : solid;
		border-width : 3px;
		margin-top : 40px;

		border-color : #000000;
	}

	.assmt-summary-question-list .tbl-heading {
		background : #000000;
		color : #FFFFFF;
	}

	.assmt-summary-question-list tr:nth-child(4n+4) {
		background-color : #F4FBF7;
	}

	.assmt-summary-question-list td {
		padding : 10px;
	}

	.summary-show-feedback-btn {
		cursor : pointer;
		color : #FFAA00;
	}

	.summary-more-info td {
		height : 0px;
	    padding : 0 10px;

		background : #45B7D8;
		color : #FFFFFF;
	}

	.summary-feedback {
		opacity : 0;
		font-size : 16px;
	}

	#content-sandbox {
		opacity : 0;
		position : absolute;
	}

	/* / SUMMARY */

	/* / ASSESSMENT */
}
