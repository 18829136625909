.num-no-to-select {
  .to-rem ( width, 80 );
}

.assessment-builder {
  .to-rem ( font-size, 13 );

  th, td {
    .to-rem ( padding, 5 14 );
    vertical-align: middle;
  }

  td:not(.action-col) {
    &::before {
      //content: '';
      //.to-rem ( height, 9 );
      //display: block;
    }
  }

  td.action-col {
    .to-rem ( width, 1 );
    white-space: nowrap;
  }

  .question {
    .to-rem ( border-width, 0 1 1 1 );
    border-style: solid;
    border-color: #EEE;

    td {
      vertical-align: top;
      .to-rem ( padding, 12 14 0 14 );
    }

    td.action-col {
      vertical-align: top;
      .to-rem ( padding, 5 14 );
    }
  }

  .section-header {
    .to-rem ( border, 1, solid #CDE );

    td {
      width: 100%;
      .to-rem ( padding, 10 14 0 14 );

      background-color: #DEF;
    }

    input[type="text"],
    input[type="number"] {
      margin-top: 0;
    }

    .section-editing-name {
      .to-rem ( padding, 10 );
    }

    .save-section-name-btn {
      .to-rem ( padding, 12 );
      .to-rem ( border, 1, solid #CCC );
      border-left: none;
      background: #F3F3F3;
      color: @color-black;

      &:hover {
        color: #0AD;
      }
    }
  }

  .question-header {
    .to-rem ( border, 1, solid #069 );

    td {
      background-color: #069;
      color: @color-white;
    }
  }

  .cb-all {
    &::before {
      font-family: FontAwesome;
      color: @tr-unselected-cb-colour;
      content: '\f0c8';
      .to-rem ( margin-top, -6 );
      .to-rem ( margin-left, -6 );
    }
  }

  .cb-all.all-selected {
    &::before {
      content: '\f14a';
      color: @tr-selected-cb-colour;
    }
  }
}

.multiple-choice-answers {

  thead {
    tr {
      .to-rem ( border, 1, #CDE solid );
    }

    th {
      background-color: #DEF;
    }
  }

  tr {
    .to-rem ( border-width, 1 );
    border-style: solid;
    border-color: #FFF #EEE #EEE #EEE;
  }

  td {
    vertical-align: middle;
  }

  .id-col      { .to-rem ( width,  120 ); }
  .text-col    { .to-rem ( width, auto ); }
  .correct-col { .to-rem ( width,  105 ); }
  .score-col   { .to-rem ( width,   90 ); }
  .delete-col  { .to-rem ( width,   24 ); }

  input[type="text"] {
    width: 100%;
  }
}

.dad-categories {

  .dad-category {
    position: relative;
    float: left;
    width: 50%;
    .to-rem ( padding, 16 );
    .to-rem ( margin, 8 0 );

    background-color: #069;

    .category-no {
      color: @color-white;

      .to-rem ( font-size, 24 );
    }

    .dad-delete-icon {
      .to-rem ( right, 0 );
      .to-rem ( top, 0 );
    }
  }

  .dad-items {
    .to-rem ( margin-top, 10 );
  }

  .dad-item {
    position: relative;

    .dad-delete-icon {
      .to-rem ( right, 0 );
      .to-rem ( top, 1 );
    }
  }

  .dad-delete-icon {
    position: absolute;
    z-index: 100;
    .to-rem ( padding, 12 14 11 14 );
    .to-rem ( border-width, 0 );
    border-style: solid;

    border-color: #FFF;
    background-color: #024;
    color: @color-white;

    cursor: pointer;

    &:hover {
      background-color: @color-white;
      color: #024;
    }
  }

  ul {
    margin-bottom: 0;
  }

  li {
    margin-left: 0;
  }

  input {
    width: 100%;
    border: none;
    .to-rem ( margin, 1 0 );

    &::placeholder {
      color: #6BD;
    }
  }

  input[name="category"] {
    background-color: #058;
    color: @color-white;
  }

  input[name*="item"] {
    background-color: #007FAF;
    color: @color-white;
  }

  .add-another-item {
    width: 100%;
    .to-rem ( margin, 2 0 );
    .to-rem ( padding, 8 );

    background-color: @color-white;
    color: @color-black;

    &:hover {
      color: #7B7;
    }
  }
}
