/* ========================================================================== *\
   Objects -> Buttons ($objects-buttons)
\* ========================================================================== */

.btn {
  font-family: @btn-font-family;
  font-weight: @btn-font-weight;
  line-height: @btn-line-height;
  display: inline-block;
  margin: 0;
  .to-rem(padding, @btn-padding);
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  color: @btn-text-color;
  border: 0 none;
  background-color: @btn-background-color;

  .antialiasing();

  /**
   * States
   */

  &:hover {
    background-color: lighten(@btn-background-color, 2%);
  }

  &:active,
  &.is-active {
    background-color: darken(@btn-background-color, 2%);
    .to-rem(box-shadow, 0 0 @spacing-third, hsla(0, 0%, 0%, 0.25) inset);
  }

  &:active:focus {
    outline: 0 none;
  }

  &:disabled,
  &[disabled],
  &.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.50;
    box-shadow: none;
    text-shadow: none;
  }
}

/**
 * Shapes
 */

.btn-round,
input[type="button"].btn-round,
input[type="submit"].btn-round {
  .to-rem(border-radius, @btn-border-radius);
}

/**
 * Sizes
 */

.btn-xs {
  .to-rem(font-size, @btn-xs-font-size);
  .to-rem(padding, @btn-xs-padding);
}

.btn-sm {
  .to-rem(font-size, @btn-sm-font-size);
  .to-rem(padding, @btn-sm-padding);
}

.btn-block {
  display: block;
}

.screen-lg-min({
  .btn-lg,
  .btn-block {
    .to-rem(font-size, @btn-lg-font-size);
    .to-rem(padding, @btn-lg-padding);
  }
});

/**
 * Colors
 */

.btn-primary {
  color: @btn-primary-text-color;
  background-color: @btn-primary-bg-color;
}

.btn-primary:hover {
  background-color: lighten(@btn-primary-bg-color, 4%);
}

.btn-primary:active,
.btn-primary.is-active {
  background-color: darken(@btn-primary-bg-color, 4%);
}

/**
 * Groups
 */

.btn-group .btn {
  .to-rem(margin, @btn-group-margin);

  &.btn-lg {
    .to-rem(margin, @btn-lg-group-margin);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
