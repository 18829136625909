/* ========================================================================== *\
   Utilities -> Font Sizes ($utilities-font-sizes)
\* ========================================================================== */

//
// fz  = font-size
// --- = smallest
// --  = smaller
// -   = small
// +   = large
// ++  = larger
// +++ = largest
// 100 = 100%
// 0   = none

/**
 * Hat tip to @csswizardry for the +/- technique.
 *
 * @link http://cbrac.co/1Pd1ZKl
 */

.fz--- {
  .to-rem(font-size, @font-size-xxx-small, ~"!important");
}

.fz-- {
  .to-rem(font-size, @font-size-xx-small, ~"!important");
}

.fz- {
  .to-rem(font-size, @font-size-x-small, ~"!important");
}

.fz {
  .to-rem(font-size, @font-size, ~"!important");
}

.fz\+ {
  .to-rem(font-size, @font-size-x-large, ~"!important");
}

.fz\+\+ {
  .to-rem(font-size, @font-size-xx-large, ~"!important");
}

.fz\+\+\+ {
  .to-rem(font-size, @font-size-xxx-large, ~"!important");
}

.fz100 {
  font-size: @font-size-100 !important;
}

.fz0 {
  font-size: 0 !important;
}

.screens({
  .xs-fz--- {
    .to-rem(font-size, @font-size-xxx-small, ~"!important");
  }

  .xs-fz-- {
    .to-rem(font-size, @font-size-xx-small, ~"!important");
  }

  .xs-fz- {
    .to-rem(font-size, @font-size-x-small, ~"!important");
  }

  .xs-fz {
    .to-rem(font-size, @font-size, ~"!important");
  }

  .xs-fz\+ {
    .to-rem(font-size, @font-size-x-large, ~"!important");
  }

  .xs-fz\+\+ {
    .to-rem(font-size, @font-size-xx-large, ~"!important");
  }

  .xs-fz\+\+\+ {
    .to-rem(font-size, @font-size-xxx-large, ~"!important");
  }

  .xs-fz100 {
    font-size: @font-size-100 !important;
  }

  .xs-fz0 {
    font-size: 0 !important;
  }
},{
  .sm-fz--- {
    .to-rem(font-size, @font-size-xxx-small, ~"!important");
  }

  .sm-fz-- {
    .to-rem(font-size, @font-size-xx-small, ~"!important");
  }

  .sm-fz- {
    .to-rem(font-size, @font-size-x-small, ~"!important");
  }

  .sm-fz {
    .to-rem(font-size, @font-size, ~"!important");
  }

  .sm-fz\+ {
    .to-rem(font-size, @font-size-x-large, ~"!important");
  }

  .sm-fz\+\+ {
    .to-rem(font-size, @font-size-xx-large, ~"!important");
  }

  .sm-fz\+\+\+ {
    .to-rem(font-size, @font-size-xxx-large, ~"!important");
  }

  .sm-fz100 {
    font-size: @font-size-100 !important;
  }

  .sm-fz0 {
    font-size: 0 !important;
  }
},{
  .md-fz--- {
    .to-rem(font-size, @font-size-xxx-small, ~"!important");
  }

  .md-fz-- {
    .to-rem(font-size, @font-size-xx-small, ~"!important");
  }

  .md-fz- {
    .to-rem(font-size, @font-size-x-small, ~"!important");
  }

  .md-fz {
    .to-rem(font-size, @font-size, ~"!important");
  }

  .md-fz\+ {
    .to-rem(font-size, @font-size-x-large, ~"!important");
  }

  .md-fz\+\+ {
    .to-rem(font-size, @font-size-xx-large, ~"!important");
  }

  .md-fz\+\+\+ {
    .to-rem(font-size, @font-size-xxx-large, ~"!important");
  }

  .md-fz100 {
    font-size: @font-size-100 !important;
  }

  .md-fz0 {
    font-size: 0 !important;
  }
},{
  .lg-fz--- {
    .to-rem(font-size, @font-size-xxx-small, ~"!important");
  }

  .lg-fz-- {
    .to-rem(font-size, @font-size-xx-small, ~"!important");
  }

  .lg-fz- {
    .to-rem(font-size, @font-size-x-small, ~"!important");
  }

  .lg-fz {
    .to-rem(font-size, @font-size, ~"!important");
  }

  .lg-fz\+ {
    .to-rem(font-size, @font-size-x-large, ~"!important");
  }

  .lg-fz\+\+ {
    .to-rem(font-size, @font-size-xx-large, ~"!important");
  }

  .lg-fz\+\+\+ {
    .to-rem(font-size, @font-size-xxx-large, ~"!important");
  }

  .lg-fz100 {
    font-size: @font-size-100 !important;
  }

  .lg-fz0 {
    font-size: 0 !important;
  }
},{
  .xl-fz--- {
    .to-rem(font-size, @font-size-xxx-small, ~"!important");
  }

  .xl-fz-- {
    .to-rem(font-size, @font-size-xx-small, ~"!important");
  }

  .xl-fz- {
    .to-rem(font-size, @font-size-x-small, ~"!important");
  }

  .xl-fz {
    .to-rem(font-size, @font-size, ~"!important");
  }

  .xl-fz\+ {
    .to-rem(font-size, @font-size-x-large, ~"!important");
  }

  .xl-fz\+\+ {
    .to-rem(font-size, @font-size-xx-large, ~"!important");
  }

  .xl-fz\+\+\+ {
    .to-rem(font-size, @font-size-xxx-large, ~"!important");
  }

  .xl-fz100 {
    font-size: @font-size-100 !important;
  }

  .xl-fz0 {
    font-size: 0 !important;
  }
},{
  .xxl-fz--- {
    .to-rem(font-size, @font-size-xxx-small, ~"!important");
  }

  .xxl-fz-- {
    .to-rem(font-size, @font-size-xx-small, ~"!important");
  }

  .xxl-fz- {
    .to-rem(font-size, @font-size-x-small, ~"!important");
  }

  .xxl-fz {
    .to-rem(font-size, @font-size, ~"!important");
  }

  .xxl-fz\+ {
    .to-rem(font-size, @font-size-x-large, ~"!important");
  }

  .xxl-fz\+\+ {
    .to-rem(font-size, @font-size-xx-large, ~"!important");
  }

  .xxl-fz\+\+\+ {
    .to-rem(font-size, @font-size-xxx-large, ~"!important");
  }

  .xxl-fz100 {
    font-size: @font-size-100 !important;
  }

  .xxl-fz0 {
    font-size: 0 !important;
  }
});
