.report-elearning,
.report-ilt-courses,
.report-oc-exercises {
  select[multiple] {
    .to-rem ( padding, 10 );
    .to-rem ( font-size, 14 );

    option {
      .to-rem ( padding, 5 );

      &:not(:last-child) {
        .to-rem ( border-bottom, 1, solid #DCE9FF );
      }
    }
  }

  .report-ilt-courses-with-filter {
    .to-rem ( border-radius, 4 );
    .to-rem ( border, 1, solid #CCC );

    .report-ilt-course-filter {
      display: table;
      width: 100%;
      .to-rem ( border-bottom, 1, solid #CCC );

      .report-ilt-course-filter-label {
        display: table-cell;
        width: 133px;
        float: left;
        .to-rem ( height, 40 );
        .to-rem ( padding, 14 );

        background-color: #CCC;
        color: #FFF;
      }

      .report-ilt-course-filter-search {
        display: table-cell;
        vertical-align: top;
        width: 100%;
        padding: 0;

        input {
          .to-rem ( height, 40 );
          margin-top: 0;
          border: none;
        }
      }

    }

    select {
      border: none;
    }
  }

  #location-report-ilt-courses {
    height: 100%;
  }

  .report-ms-courses {
    float: left;
    width: 100%;

    .screen-md-min({
      width: 60%;
    });

    header {
      .to-rem ( height, 45 );
    }

    select {
      width: 100%;
      .to-rem ( height, 200 );
    }
  }

  .report-ms-statuses {
    float: left;
    width: 100%;
    .to-rem ( height, 260 );

    .screen-md-min({
      width: 35%;
      .to-rem ( margin-left, 10 );
    });

    select {
      width: 100%;
      .to-rem ( height, 200 );
    }
  }

  .report-filter-section-location {
    .to-rem ( height, 310 );
  }

  .report-location-selector {
    float: left;
    width: 100%;
    .to-rem ( height, 260 );

    .screen-md-min({
      width: 60%;
    });

    .location-selector {
      width: 100%;
      .to-rem ( height, 200 );
    }
  }

  .report-field-select {
    float: left;
    width: 100%;

    .to-rem ( margin-top, 19 );

    .screen-md-min({
      width: 35%;
      .to-rem ( margin-left, 10 );
    });

    h4 {
      .to-rem ( margin-bottom, 11 );
    }
  }
}

.report-result {
  h3:first-child {
    .to-rem ( margin-top, 36 );
  }

  .elearning-report-tbl-separator,
  .ilt-courses-report-tbl-separator,
  .oc-exercises-report-tbl-separator {
    .to-rem ( height, 16 );
    .to-rem ( margin, 20 0 );
    .to-rem ( border-radius, 4 );
    .to-rem ( border, 1, solid #EEBB00 );
    .to-rem ( border-bottom-width, 8 );
    background-color: #FFCC00;
  }

  .expired {
    background-color: #F00;
    color: @color-white;
  }

  .expiring-soon {
    background-color: #F80;
    color: @color-white;
  }

  .report-elearning-overall-controls,
  .report-ilt-overall-controls,
  .sr-section {
    .to-rem ( padding, 20 0 50 0 );
    .to-rem ( border-radius, 4 );
    .to-rem ( border, 1, solid #CCC );
    .to-rem ( border-bottom-width, 4 );

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,e2e2e2+100 */
    background: #eeeeee; /* Old browsers */
    background: -moz-linear-gradient(top,  #eeeeee 0%, #e2e2e2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #eeeeee 0%,#e2e2e2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #eeeeee 0%,#e2e2e2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#e2e2e2',GradientType=0 ); /* IE6-9 */
  }

  .report-course-heading {
    .to-rem ( padding, 12 0 );

    background-color: @color-white;

    .course-label {
      .to-rem ( padding, 8 );

      background-color: #3B65B1;
      color: @color-white;
    }

    .course-name {
      .to-rem ( padding, 8 );

      background-color: @color-white;
      color: #3B65B1;
    }
  }

  .report-site-name {
    color: #797979;

    .to-rem ( font-size, 40 );
  }

  .ind-pc-header {
    position: relative;
    .to-rem ( padding, 20 0 0 20 );
    .to-rem ( margin-bottom, 20 );
    .to-rem ( border-radius, 4 );
    .to-rem ( border, 1, solid #CCC );
    .to-rem ( border-bottom-width, 4 );

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,e2e2e2+100 */
    background: #eeeeee; /* Old browsers */
    background: -moz-linear-gradient(top,  #eeeeee 0%, #e2e2e2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #eeeeee 0%,#e2e2e2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #eeeeee 0%,#e2e2e2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#e2e2e2',GradientType=0 ); /* IE6-9 */

  }

  .print-section-btn {
    position: absolute;
    .to-rem ( left, 10 );
    .to-rem ( bottom, 10 );
  }

  .pie-chart-name {
    font-weight: bold;
    .to-rem ( font-size, 14 );
    text-align: center;
  }

  .ind-chart-no-data {
    display: block;
    .to-rem ( padding, 10 );
    .to-rem ( margin, 24 20 24 0 );
    .to-rem ( border-radius, 5 );

    background-color: #DD2525;
    color: @color-white;

    font-style: italic;
    text-align: center;
  }
}

.chart-reset-btn {
  circle {
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.2);
  }
}

.reset-date-range {
  color: #C00;
  cursor: pointer;
}
