/* ========================================================================== *\
   Base -> Text Elements ($base-text-elements)
\* ========================================================================== */

/**
 * Add a help cursor to elements that need one.
 */

abbr,
acronym,
dfn[title] {
  cursor: help;
}

/**
 * Remove `text-decoration` on links inside <abbr> elements, since there are
 * already border styles on the <abbr> element.
 */

abbr a {
  text-decoration: none;
}

/**
 * Set <acronym> elements to be uppercase by default.
 */

acronym {
  text-transform: uppercase;
}

/**
 * Set some basic styles for <del> tags.
 */

del {
  font-style: @text-del-font-style;
  text-decoration: @text-del-text-decoration;
  color: @text-del-text-color;
}

/**
 * Give <details> elements a pointer cursor.
 */

details {
  cursor: pointer;
}

/**
 * Set some basic styles for definition lists.
 */

dl {
  dt {
    font-weight: @text-dt-font-weight;
    color: @text-dt-text-color;
  }
  dd {
    margin: 0;
  }
}

/**
 * Ensure commonly italicized elements are italicized.
 */

em,
i,
cite {
  font-style: italic;
}

/**
 * Address styling not present in IE9.
 */

ins,
mark {
  text-decoration: none;
  color: @text-highlights-text-color;
  background: @text-highlights-bg-color;
}
