/* ========================================================================== *\
   Base -> Anchors ($base-anchors)
\* ========================================================================== */

a {
  text-decoration: underline;
  color: @anchor-text-color;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
