/* ========================================================================== *\
   Base -> Reset ($base-reset)
\* ========================================================================== */

//
// Styles
//

/**
 * `border-box`... ALL THE THINGS!
 *
 * @link http://cbrac.co/RQrDL5
 */

html {
  box-sizing: border-box;
}

/**
 * Inheriting the `box-sizing` property is a better practice.
 *
 * @link http://cbrac.co/1tqPFsy
 */

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

/**
 * Remove margins, paddings, and borders from HTML elements.
 *
 * @link http://cbrac.co/1Ev9etR
 */

a,
abbr,
acronym,
address,
applet,
article,
aside,
b,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
menu,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
u,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
}

/**
 * Remove default border for <iframe> elements.
 *
 * @link http://cbrac.co/1CVyNTj
 */

iframe {
  border: 0;
}

/**
 * Suppress the focus outline on links that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 *
 * It is okay to use `!important` here because we always want this rule to take
 * precedence.
 *
 * @link http://cbrac.co/1Evazku
 */

[tabindex="-1"]:focus {
  outline: none !important;
}
