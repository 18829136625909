table {
  &:not(.no-hover) {
    tr {
      &:not(.no-hover) {
        &:hover {
          td {
            background-color: @tr-hover-bg-colour;
            color: @tr-hover-text-colour;
          }
        }
      }
    }
  }
}
th, td {
  .to-rem ( padding, 14 );
}

th {
  color: @table-head-text-colour;
}

td {
  background-color: @color-white;
}

.cb-td {
  width: 0;
}

// - dataTables

.dataTable {
  .to-rem ( font-size, 13 );

  th, td {
    vertical-align: middle;
  }

  td:not(.action-col) {
    &::before {
      //content: '';
      //.to-rem ( height, 9 );
      //display: block;
    }
  }

  td.action-col {
    .to-rem ( width, 1 );
    white-space: nowrap;
  }

  .cb-all {
    &::before {
      font-family: FontAwesome;
      color: @tr-unselected-cb-colour;
      content: '\f0c8';
      .to-rem ( margin-top, -6 );
      .to-rem ( margin-left, -6 );
    }
  }

  .cb-all.all-selected {
    &::before {
      content: '\f14a';
      color: @tr-selected-cb-colour;
    }
  }

  .dt-single-line-row {
    .to-rem ( padding, 16 10 );
  }
}

.dataTables_filter input {
  .to-rem ( padding, 6 );
  .to-rem ( border-radius, 4 );
  .to-rem ( border-width, @clickables-border-width );
  border-style: solid;

  border-color: @form-input-border-colour;
  border-bottom-color: @clickables-border-bottom-colour;
}

.dataTables_length {
  select {
    display: inline;

    .to-rem ( border-radius, 4 );
    .to-rem ( border-width, @clickables-border-width );
    border-style: solid;

    border-color: @form-input-border-colour;
    border-bottom-color: @clickables-border-bottom-colour;
  }
}

.paginate_button {
  .to-rem ( border-radius, 4 );
}

.paginate_button.current {
  .to-rem ( border-width, 1 );
  border-style: solid;
  border-color: #DDD !important;

  &:hover {
    color: @color-white !important;
  }
}

.paginate_button {
  &:hover {
    background: #F93 !important;
    border-color: #C60 !important;
  }
}

.table-label {
  background-color: #F1F1F1;
  color: #777;
}

// - FAKE TABLE

.fake-table {
  display: table;
  width: 100%;
}

.fake-cell {
  display: table-cell;
  vertical-align: middle;
}

// - / FAKE TABLE

.add-another-row {
  background-color: #E0FFDD;
  .to-rem ( border, 1, solid #CDEFD3 );
  color: #181;

  &:hover {
    background-color: #DEC;
    .to-rem ( border, 1, solid #BDC );
  }
}
