// - @FORUM

#breadcrumb-1 {

}

#breadcrumb-2 {
  .to-rem ( margin-top, 16 );
}

.forum-breadcrumbs {
  li {
    display: inline;
    .to-rem ( margin-left, 0 );
    .to-rem ( margin-right, 3 );

    a {
      .to-rem ( padding, 5 );
      .to-rem ( border-radius, 4 );
      background-color: @color-white;
      .to-rem ( font-size, 14 );
      text-decoration: none;

      &:hover {
        background-color: #069;
        color: @color-white;
      }
    }

    i {
      position: relative;
      .to-rem ( top, -1 );
      .to-rem ( font-size, 10 );
    }
  }
}

table {
  &.forum-categories {

    .forum-category {
      td {
        background-color: #F9F9F9;
      }

      &.sticky {
        td {
          background-color: @color-white;
        }
      }
    }

    .to-rem ( line-height, 16 );

    tbody td {
      .to-rem ( padding, 16 );
    }

    .forum-category-identifier {
      .to-rem ( font-size, 16 );
    }

    .forum-category-desc {

    }

  }

  &.forum-posts {
    td {
      .to-rem ( line-height, 18 );

      .forum-post-body {
        display: block;

        .to-rem ( margin-bottom, 16 );

        p, ul, ol {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .forum-post-button {
        .to-rem ( padding, 3 5 2 5 );
        .to-rem ( border-radius, 3 );

        .to-rem ( border, 1, solid #CCC );
        background-color: #E2E2E2;
      }
    }
  }
}
