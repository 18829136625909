// @NEWS

.article-list {
  article {
    .to-rem ( padding, 24 );
    .to-rem ( margin-bottom, 24 );

    background-color: @color-white;

    header {
      .headline {
        .to-rem ( padding-bottom, 16 );
        .to-rem ( margin-bottom, 12 );

        .to-rem ( border-bottom, 1, solid #EEE );
      }

      .news-date {
        color: @top-level-link-colour;

        .to-rem ( font-size, 12 );
        font-style: italic;
      }
    }

    footer {
      a {
        float: right;
        color: @top-level-link-colour;
        text-decoration: none;
      }

      .to-rem ( height, 16 );
    }
  }
}

.news-sidebar {
  .to-rem ( margin-top, 24 );

  .screen-md-min({
    float: right;
    .to-rem ( padding-left, 24 );
    .to-rem ( margin-top, 0 );
  });
}

.news-sidebar {
  padding-left: 0;

  .news-categories-sidebar,
  .news-related-courses-sidebar {
    width: 100%;
  }
}

.news-categories-sidebar,
.news-related-courses-sidebar {
  width: 100%;

  .screen-md-min({
    float: right;
    width: 25%;
  });

  a {
    display: block;
    .to-rem ( margin, 3 0 );
    .to-rem ( padding, 10 );

    background-color: @color-white;
    color: @top-level-link-colour;

    .to-rem ( line-height, 20 );
    .to-rem ( font-size, 14 );
    text-decoration: none;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      background-color: @top-level-link-colour;
      color: @color-white;
    }
  }
}

.news-related-courses-sidebar {
  .to-rem ( margin-top, 24 );
}

.single-article {
  .to-rem ( padding-left, 24 );

  .screen-md-min({
    padding-left: 0;
  });

  .article-inner {
    .to-rem ( padding, 16 );

    background-color: @color-white;

    img {
      width: 100%;
    }
  }
}
