/* ========================================================================== *\
   Utilities -> Positions ($utilities-positions)
\* ========================================================================== */

//
// pos = position
// a   = absolute
// f   = fixed
// r   = relative
// s   = static
//

.posa {
  position: absolute !important;
}

.posf {
  position: fixed !important;
}

.posr {
  position: relative !important;
}

.poss {
  position: static !important;
}

.screens({
  .xs-posa {
    position: absolute !important;
  }

  .xs-posf {
    position: fixed !important;
  }

  .xs-posr {
    position: relative !important;
  }

  .xs-poss {
    position: static !important;
  }
},{
  .sm-posa {
    position: absolute !important;
  }

  .sm-posf {
    position: fixed !important;
  }

  .sm-posr {
    position: relative !important;
  }

  .sm-poss {
    position: static !important;
  }
},{
  .md-posa {
    position: absolute !important;
  }

  .md-posf {
    position: fixed !important;
  }

  .md-posr {
    position: relative !important;
  }

  .md-poss {
    position: static !important;
  }
},{
  .lg-posa {
    position: absolute !important;
  }

  .lg-posf {
    position: fixed !important;
  }

  .lg-posr {
    position: relative !important;
  }

  .lg-poss {
    position: static !important;
  }
},{
  .xl-posa {
    position: absolute !important;
  }

  .xl-posf {
    position: fixed !important;
  }

  .xl-posr {
    position: relative !important;
  }

  .xl-poss {
    position: static !important;
  }
},{
  .xxl-posa {
    position: absolute !important;
  }

  .xxl-posf {
    position: fixed !important;
  }

  .xxl-posr {
    position: relative !important;
  }

  .xxl-poss {
    position: static !important;
  }
});
