// =============================================================================
// Base -> Mixins -> Media Queries
// =============================================================================

//
// Media query mixins for various screen sizes.
//
// @link http://cbrac.co/1tB2TXR
//

// Extra-small and up
.screen-xs-min(@rules) {
  @media (min-width: @screen-xs-min) {
    @rules();
  }
}

// Extra-small only
.screen-xs(@rules) {
  @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
    @rules();
  }
}

// Small and up
.screen-sm-min(@rules) {
  @media (min-width: @screen-sm-min) {
    @rules();
  }
}

// Small only
.screen-sm(@rules) {
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    @rules();
  }
}

// Medium and up
.screen-md-min(@rules) {
  @media (min-width: @screen-md-min) {
    @rules();
  }
}

// Medium only
.screen-md(@rules) {
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    @rules();
  }
}

// Large and up
.screen-lg-min(@rules) {
  @media (min-width: @screen-lg-min) {
    @rules();
  }
}

// Large only
.screen-lg(@rules) {
  @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
    @rules();
  }
}

// Extra-large and up
.screen-xl-min(@rules) {
  @media (min-width: @screen-xl-min) {
    @rules();
  }
}

// Extra-large only
.screen-xl(@rules) {
  @media (min-width: @screen-xl-min) and (max-width: @screen-xl-max) {
    @rules();
  }
}

// Extra-extra-large and up
.screen-xxl-min(@rules) {
  @media (min-width: @screen-xxl-min) {
    @rules();
  }
}

//
// Chain media queries together
//
// Usage:
//
// .my-element {
//   .screen({
//     color: red;    // xs and up
//   },{
//     color: green;  // sm and up
//   },{
//     color: yellow; // md and up
//   },{
//     color: purple; // lg and up
//   },{
//     color: blue;   // xl and up
//   },{
//     color: orange; // xxl and up
//   });
// }
//
// @link http://cbrac.co/1tB2TXR
//

.screens(@rules-xs, @rules-sm, @rules-md, @rules-lg, @rules-xl, @rules-xxl) {
  .screen-xs-min(@rules-xs);
  .screen-sm-min(@rules-sm);
  .screen-md-min(@rules-md);
  .screen-lg-min(@rules-lg);
  .screen-xl-min(@rules-xl);
  .screen-xxl-min(@rules-xxl);
}
