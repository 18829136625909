.tab-nav{

  ul {
    .to-rem ( margin-bottom, -1 );
  }

  li {
    position: relative;
    display: inline-block;
    .to-rem ( padding, 8 );
    .to-rem ( margin, 0 );

    .to-rem ( border-radius, 5 5 0 0 );
    background-color: @dark-link-colour;
    .to-rem ( border, 1, #DDD solid );
    box-shadow: inset 0px -8px 14px rgba(0, 51, 76, 0.15);

    &:hover {
      background-color: #FFF;

      a {
        color: @dark-link-colour;
      }
    }
  }

  .selected,
  .tab-nav-title {
    border-bottom-color: #FFF;
    background-color: #FFF;
    color: #000;

    box-shadow: none;
  }

  .selected {
    a {
      color: @dark-link-colour;
    }
  }

  a {
    color: #FFF;
    text-decoration: none;

    cursor: pointer;
  }
}

.tab-section {
  .to-rem ( padding, 20 );
  background-color: #FFF;
  .to-rem ( border, 1, #DDD solid );
}
