.bug-tracker {
  position: fixed;
  z-index: @Z-bug-tracker;
  width: 60%;
  right: -60%;
  .to-rem ( top, 30 );
  .to-rem ( bottom, 30 );
  .to-rem ( padding, 30 );
  .to-rem ( border-radius, 14 0 0 14 );
  .to-rem ( border-width, 3 0 3 8 );
  border-style: solid;
  border-color: #FF8400;

  background-color: @color-white;
  box-shadow: -1px 0px 11px rgba(0,0,0,0.1);

  main {
    max-height: 40%;
    overflow-y: scroll;
  }

  .comments-section {

    .comments {
      .to-rem ( height, 145 );
      overflow-y: scroll;

      blockquote {
        margin-left: 0;
        .to-rem ( margin-bottom, 0 );
        .to-rem ( padding, 16 );
        background-color: #F9F9F9;
        .to-rem ( font-size, 16 );
      }

      cite {
        float: right;
        .to-rem ( padding, 5 );
        .to-rem ( margin-bottom, 10 );
        background-color: #DEDEDE;
        color: #fff;
        .to-rem ( font-size, 13 );
      }
    }
  }

  .ui-selectmenu-button {
    border: none;

    .ui-icon-triangle-1-s {
      &::before {
        color: #CCC;
      }
    }
  }

  textarea#desc,
  textarea#new-comment {
    min-height: 50px;
  }
}

.red-flag {
  color: #D00;
}

.amber-flag {
  color: #FC0;
}

.green-flag {
  color: #7B7;
}

.bug-tracker-toggle-btn {
  position: absolute;
  .to-rem ( top, 30 );
  right: 100%;
  .to-rem ( padding, 15 );
  .to-rem ( border-radius, 12 0 0 12 );

  background-color: #FF8400;
  color: @color-white;
}
