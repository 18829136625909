.faqs {
  .faq {
    .to-rem ( padding, 16 );
    .to-rem ( margin, 16 0 );
    .to-rem ( border, 1, solid #DDD );
    background-color: @color-white;

    header {
      label {
        &:hover {
          + .faq-action {
            display: block;
          }
        }
      }

      .faq-action {
        display: none;
        float: right;
        .to-rem ( margin-top, 2 );
        color: #5B5;
        text-transform: uppercase;
      }
    }

    .faq-question {
      a {
        .to-rem ( font-size, 16 );
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]:checked + .faq-answer {
      .to-rem ( max-height, 2000 );
      .to-rem ( margin-top, 16 );

      transition: max-height .9s, margin-top .15s;
    }

    .faq-answer {
      max-height: 0;
      margin-top: 0;
      overflow-y: hidden;

      transition: max-height .9s, margin-top .15s ease .9s;

      .a {
        .to-rem ( font-size, 16 );
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:hover {
      background-color: #EEFFEE;
      border-color: #CDC;
    }
  }
}
