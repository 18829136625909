.DT-qualification-items-list {
  tr.locked {
    td {
      background-color: #EEE;
      color: #BBB;
    }

    button, a {
      visibility: hidden;
    }

    .sm-circle-icon {
      display: none;

      &.locked {
        display: inline;
      }
    }
  }

  tr:not(.locked) {
    .sm-circle-icon {
      &:not(.locked) {
        display: inline;
      }

      &.locked {
        display: none;
      }
    }
  }
}

.DT-qual-add-manual-users {
  input[type="date"] {
    .to-rem ( padding, 7 7 6 7 );
  }

  button {
    background: none;
    border: none;
  }
}
