/* ========================================================================== *\
   Utilities -> Vertical Alignment ($utilities-vertical-alignment)
\* ========================================================================== */

//
// Vertical alignment
//
// va = vertical-align
// t  = top
// b  = bottom
// m  = middle
//

.vat {
  vertical-align: top !important;
}

.vab {
  vertical-align: bottom !important;
}

.vam {
  vertical-align: middle !important;
}

.screens({
  .xs-vat {
    vertical-align: top !important;
  }

  .xs-vab {
    vertical-align: bottom !important;
  }

  .xs-vam {
    vertical-align: middle !important;
  }
},{
  .sm-vat {
    vertical-align: top !important;
  }

  .sm-vab {
    vertical-align: bottom !important;
  }

  .sm-vam {
    vertical-align: middle !important;
  }
},{
  .md-vat {
    vertical-align: top !important;
  }

  .md-vab {
    vertical-align: bottom !important;
  }

  .md-vam {
    vertical-align: middle !important;
  }
},{
  .lg-vat {
    vertical-align: top !important;
  }

  .lg-vab {
    vertical-align: bottom !important;
  }

  .lg-vam {
    vertical-align: middle !important;
  }
},{
  .xl-vat {
    vertical-align: top !important;
  }

  .xl-vab {
    vertical-align: bottom !important;
  }

  .xl-vam {
    vertical-align: middle !important;
  }
},{
  .xxl-vat {
    vertical-align: top !important;
  }

  .xxl-vab {
    vertical-align: bottom !important;
  }

  .xxl-vam {
    vertical-align: middle !important;
  }
});
