.ilt-sessions {
  .to-rem ( margin-top, 30 );
}

.awarding-body-logo-field {
  .to-rem ( padding, 10 );
  .to-rem ( height, 160 );
  .to-rem ( border-radius, 4 );
  .to-rem ( border-width, 1 );
  border-style: solid;

  border-color: #CCC;
  background-color: @color-white;
}

.awarding-body-logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.mdd-ilt-assessments {
  .to-rem ( height, 150 );
}