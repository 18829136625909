.steps-nav-container {

  text-align: center;

  .steps-nav {

    li {
      display: inline-block;
    }

    .step-icon {
      position: relative;
      z-index: 10;
      .to-rem ( width, 100 );

      vertical-align: top;

      .step-name {
        display: block;
        .to-rem ( margin, 5 auto );
        .to-rem ( width, 60 );

        color: #CCC;

        .to-rem ( font-size, 12 );
      }
    }

    .step-join {
      position: relative;
      z-index: 5;
      .to-rem ( top, 3 );
      .to-rem ( margin-left, -40 );
      .to-rem ( margin-right, -64 );
      .to-rem ( width, 100 );
      .to-rem ( border-top, 2, solid #CCC );
    }
  }
}

.highlight-box-1 {
  border: 1px solid #06C;

  background-color: #6CF;
}

.custom-dropdown {
  -webkit-appearance: none;

  background-image: url('/assets/graphics/dropdown-arrow-666.png');
  background-repeat: no-repeat;
  background-position: center right 9px;
}

.deadline-selector {
  display: inline;
  box-sizing: content-box;
  .to-rem ( padding, 10 24 10 8 );
  .to-rem ( height, 14 );
  .to-rem ( border-radius, 4 );

  .to-rem ( margin-right, 5 );

  .to-rem ( font-size, 13 );

  .custom-dropdown;
}

.custom-deadline {
  display: inline;
}

.custom-deadline-number {
  display: inline;
  box-sizing: content-box;
  .to-rem ( width, 40 );
  .to-rem ( height, 14 );
  .to-rem ( padding, 10 );
  .to-rem ( border-radius, 5 0 0 5 );
  border-right: none;

  .to-rem ( font-size, 13 );

  &.invalid {
    background-color: #FCC;
    border-color: #F99 #CCC #F99 #F99;
  }
}

.custom-deadline-unit {
  display: inline;
  box-sizing: content-box;
  .to-rem ( padding, 10 24 10 8 );
  .to-rem ( width, 65 );
  .to-rem ( height, 14 );

  .custom-dropdown;

  border-radius: 0 5px 5px 0;
  border-left-color: #F8F8F8;

  .to-rem ( font-size, 13 );
}

.deadline-date-input {
  display: inline;
  box-sizing: content-box;
  .to-rem ( width, 68 );
  .to-rem ( height, 14 );
  .to-rem ( padding, 10 );
  .to-rem ( border-radius, 5 );
  border-right: none;

  .to-rem ( font-size, 13 );
}
