.terms {
  ol {
    .to-rem ( padding, 50 70 );

    .to-rem ( border, 1, solid #CCC );
    background-color: #EEE;
    color: #222;

    font-family: Courier;
    .to-rem ( font-size, 15 );

    li {
      .to-rem ( margin, 16 0 );
    }
  }
}

.agree-terms {
  margin: 0 auto;
  .to-rem ( padding, 8 );
  .to-rem ( max-width, 300 );

  color: @color-white;
  background-color: #3a64b0;

  text-align: center;
}
