/* ========================================================================== *\
   Objects -> Forms ($objects-forms)
\* ========================================================================== */

.form-label {
  display: inline-block;
}

.form-label + .form-input,
.form-label + .form-select,
.form-label + .form-textarea {
  .to-rem(margin-top, @form-label-margin);
}

/**
 * Give certain <input>, <select>, and <textarea> elements some default
 * styles
 */

.form-input[type="date"],
.form-input[type="datetime"],
.form-input[type="datetime-local"],
.form-input[type="email"],
.form-input[type="month"],
.form-input[type="number"],
.form-input[type="password"],
.form-input[type="search"],
.form-input[type="tel"],
.form-input[type="text"],
.form-input[type="time"],
.form-input[type="url"],
.form-input[type="week"],
.form-select,
.form-textarea {
  line-height: @form-input-line-height;
  display: block;
  width: 100%;
  .to-rem(height, @form-input-height);
  .to-rem(padding, @form-input-padding);
  color: @form-input-text-color;
  .to-rem(border, @form-input-border-width, @form-input-border-style @form-input-border-color);
  outline: 0 none;
  &:focus {
    border-color: @form-input-focus-border-color;
    outline: 0 none;
  }
}

/**
 * 1. Increase padding to correct line-height in Firefox
 * 2. Give <select> menus a solid background color
 */

.form-select {
  .to-rem(padding, @form-select-padding);    /* 1 */
  background: @form-select-background-color; /* 2 */
}

.form-input-round {
  .to-rem(border-radius, @form-input-border-radius);
}
