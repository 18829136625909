.viewing-document {
  .to-rem ( height, 500 );
  .to-rem ( padding, 5 );
  .to-rem ( border-radius, 3 );

  .to-rem ( border, 1, solid #F8F8F8 );

  overflow-y: scroll;

  .viewing-document-hint {
    .to-rem ( margin-top, 30 );
    color: #CCC;
    text-align: center;
  }
}
