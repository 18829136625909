.dashboard-section-header {
  .to-rem ( padding, 14 );
  border-left: 4px solid #4483AC;
  border-bottom: 2px solid #4483AC;
}

.dashboard-section {
  .to-rem ( margin-bottom, 40 );
  .to-rem ( padding, 32 32 14 10 );
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2);

  background-color: @color-white;

  li {
    display: inline;
    float: left;
    margin-left: 0;
    .to-rem ( width, 120 );

    text-align: center;
  }

  a {
    color: #999;

    text-decoration: none;

    &:hover {
      color: #3B65B1;

      .dashboard-icon-text {
        color: #3B65B1;
      }
    }
  }

  .dashboard-icon {
    position: relative;
    width: 100%;
    .to-rem ( margin-right, 18 );
    .to-rem ( padding, 0 );
    .to-rem ( border-radius, 5 );

    background-color: @color-white;

    i, svg {
      .to-rem ( font-size, 42 );
    }

    .my-itr-icon-small {
      position: relative;
      .to-rem ( top, -2 );
      .to-rem ( margin-right, 3 );

      font-weight: normal;
      .to-rem ( font-size, 18 );
      font-family: Arial;
      font-style: italic;
    }

    .my-itr-icon-large {
      position: relative;
      .to-rem ( top, 4 );

      font-weight: bold;
      .to-rem ( font-size, 32 );
      font-family: "Arial Black";
    }
  }

  .dashboard-icon-text {
    display: block;
    width: 60%;
    .to-rem ( margin, 5 auto 0 );

    text-align: center;
    .to-rem ( font-size, 10 );
    text-decoration: none;
  }
}

a {
  .user-sharing,
  .shared-by-me {

    .to-rem ( height, 42 );

    i, svg {
      position: absolute;
    }

    .fa-user.me {
      .to-rem ( top, 6 );
      .to-rem ( left, 45 );

      .to-rem ( font-size, 33 );

      z-index: 2;
    }
    .fa-user.user-1 {
      .to-rem ( top, -3 );
      .to-rem ( left, 59 );

      color: #BBB;

      .to-rem ( font-size, 24 );

      z-index: 1;
    }
    .fa-reply {
      .to-rem ( top, 16 );
      .to-rem ( left, 68 );

      color: #666;

      .to-rem ( font-size, 16 );

      z-index: 2;
      transform: rotate(50deg);
    }
  }

  .user-sharing {
    .fa-plus {
      .to-rem ( top, -2 );
      .to-rem ( left, 74 );

      color: #666;

      .to-rem ( font-size, 16 );

      z-index: 2;
    }
  }

  .shared-with-me {

    .to-rem ( height, 42 );

    i, svg {
      position: absolute;
    }

    .fa-user.me {
      .to-rem ( top, 6 );
      .to-rem ( left, 45 );

      .to-rem ( font-size, 33 );

      z-index: 2;
    }
    .fa-user.user-1 {
      .to-rem ( top, -2 );
      .to-rem ( left, 60 );

      color: #BBB;

      .to-rem ( font-size, 24 );

      z-index: 1;
    }
    .fa-share {
      .to-rem ( top, 15 );
      .to-rem ( left, 70 );

      color: #666;

      .to-rem ( font-size, 16 );

      z-index: 2;
      transform: rotate(130deg);
    }
  }

  &:hover {
    .dashboard-icon {
      .fa-user.me {
        color: #3B65B1;
      }
      .fa-user.user-1 {
        color: #5B75D1;
      }
      .fa-reply, .fa-share {
        color: #0B2571;
      }
      .fa-plus {
        color: #0B2571;
      }
    }
  }
}

.roles-icon {
  .dashboard-icon-reverse-svg {
    position: relative;
    width: 100%;
    .to-rem ( height, 44 );

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      .to-rem ( margin-left, -23 );
      .to-rem ( width, 41 );
      height: calc(100%-5px);
      background-color: #999;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      .to-rem ( margin-left, -23 );
      .to-rem ( width, 44 );
      height: 100%;
      background-image: url('/assets/graphics/role-icon-reverse.svg');
    }
  }

  a {
    &:hover {
      .dashboard-icon-reverse-svg {
        &::before {
          background-color: #3B65B1;
        }
      }
    }
  }
}
