/* ========================================================================== *\
   Base -> Tables ($base-tables)
\* ========================================================================== */

table {
  width: 100%;
  empty-cells: show;
}

th,
tfoot td {
  text-align: left;
}

th,
td {
  overflow: visible;
}
