button[name="btn-register"] {
  .to-rem ( padding, 16 6 );
  width: 100%;
  .to-rem ( font-size, 20 );
}

.registration-enticement {

  .to-rem ( padding, 40 40 35 40 );
  //.to-rem ( margin-top, 80 );
  .to-rem ( border-radius, 20 );

  background-color: #DEDEDE;
  color: #A2A2A2;
  .to-rem ( border, 7, solid #D2D2D2 );

  .fa {
    float: left;
    .to-rem ( margin-right, 16 );

    .to-rem ( font-size, 100 );
  }

  p {
    .to-rem ( margin-top, 19 );
    .to-rem ( line-height, 21 );
  }
}
