.tour-guide {
  position: fixed;
  z-index: 11000;
  width: 100%;

  background-color: #FA0;
  opacity: 0;

  .tour-guide-progress {

    width: 0;
    .to-rem ( height, 3 );

    background-color: #F60;
  }

  .tour-guide-text {
    width: ~"calc(100% - 100px)";
    .to-rem ( padding, 10 16 );

    color: #FFF;
    opacity: 0;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .tour-guide-btns {

    .tour-guide-play-pause-btn {
      position: absolute;
      .to-rem ( top, 10 );
      .to-rem ( right, 35 );
      .to-rem ( width, 42 );

      background: none;
      border: none;

      color: #B70;
    }

    .tour-guide-close-btn {
      position: absolute;
      .to-rem ( top, 10 );
      .to-rem ( right, 5 );
      .to-rem ( width, 42 );

      background: none;
      border: none;

      color: #B70;
    }

    .tour-guide-time {
      position: absolute;
      .to-rem ( width, 30 );
      .to-rem ( right, 10 );
      .to-rem ( bottom, 5 );

      color: #FFF;

      .to-rem ( font-size, 8 );
      text-align: left;
    }
  }
}

.tour-guide-mouse {
  position: absolute;
  // .to-rem ( top, 100 );
  // .to-rem ( left, 100 );
  z-index: 14000;
  .to-rem ( width, 18 );
  .to-rem ( height, 24 );

  .to-rem ( background-size, 16 24 );
  background-image: url('/assets/graphics/tour-guide/mouse-blue.png');
  background-repeat: no-repeat;

  opacity: 0;

  .tg-mouse-circle {
    position: absolute;
    .to-rem ( top, -19 );
    .to-rem ( left, -19 );
    transform-origin: center;
    .to-rem ( width, 40 );
    .to-rem ( height, 40 );
    .to-rem ( border, 2, solid #0076C6 );
    .to-rem ( border-radius, 20 );
    opacity: 0;
  }
}

.tour-guide-btn-flash {
  background-color: #F60;
  border-color: #E50;
  color: #FFF;
}

.guide-is-available {
  position: fixed;
  .to-rem ( top, 127 );
  .to-rem ( right, -368 );
  .to-rem ( padding, 16 );
  .to-rem ( border-radius, 8 0 0 8 );
  z-index: 11500;
  transform: matrix(1, 0, 0, 1, 0, -16);

  background-color: #7B7;
  color: #FFF;

  cursor: pointer;

  .text-btn {
    background: none;
    border: none;
    .to-rem ( font-size, 24 );
  }

  .guide-available-text {
    position: relative;
    .to-rem ( top, -3 );
  }
}

.tour-guide-big-play-btn {
  position  : fixed;
  top       : 50%;
  left      : 50%;
  z-index   : 13000;
  width     : 20vw;
  height    : 20vw;
  transform : translate(-50%,-50%);

  button {
    position         : absolute;
    width            : 20vw;
    height           : 20vw;
    border-radius    : 10vw;
    padding          : 0;
    color            : #7C7;
    background-color : #FFF;
    font-size        : 20vw;

    i {
      position : absolute;
      top      : 0;
      left     : 1.35vw;
    }
  }

  div {
    position: absolute;
    bottom: -2.3vw;
    left: 3.1vw;
    width: 14vw;
    padding: 0.5vw;
    border-radius: 1vw;
    background-color: #FFF;
    color: #5A5;
    text-align: center;
  }
}

body {
  *.tg-generic-blackout-start {
    position: relative !important;
    z-index: 10000 !important;
  }
}
