.rotate-30 {
  -ms-transform: rotate(30deg); /* IE 9 */
  -webkit-transform: rotate(30deg); /* Chrome, Safari, Opera */
  transform: rotate(30deg);
}

.rotate-45 {
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.rotate-60 {
  -ms-transform: rotate(60deg); /* IE 9 */
  -webkit-transform: rotate(60deg); /* Chrome, Safari, Opera */
  transform: rotate(60deg);
}

.rotate-90 {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  transform: rotate(90deg);
}

.rotate-120 {
  -ms-transform: rotate(120deg); /* IE 9 */
  -webkit-transform: rotate(120deg); /* Chrome, Safari, Opera */
  transform: rotate(120deg);
}

.rotate-135 {
  -ms-transform: rotate(135deg); /* IE 9 */
  -webkit-transform: rotate(135deg); /* Chrome, Safari, Opera */
  transform: rotate(135deg);
}

.rotate-150 {
  -ms-transform: rotate(150deg); /* IE 9 */
  -webkit-transform: rotate(150deg); /* Chrome, Safari, Opera */
  transform: rotate(150deg);
}

.rotate-180 {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}

.rotate-210 {
  -ms-transform: rotate(210deg); /* IE 9 */
  -webkit-transform: rotate(210deg); /* Chrome, Safari, Opera */
  transform: rotate(210deg);
}

.rotate-225 {
  -ms-transform: rotate(225deg); /* IE 9 */
  -webkit-transform: rotate(225deg); /* Chrome, Safari, Opera */
  transform: rotate(225deg);
}

.rotate-240 {
  -ms-transform: rotate(240deg); /* IE 9 */
  -webkit-transform: rotate(240deg); /* Chrome, Safari, Opera */
  transform: rotate(240deg);
}

.rotate-270 {
  -ms-transform: rotate(270deg); /* IE 9 */
  -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
  transform: rotate(270deg);
}

.rotate-300 {
  -ms-transform: rotate(300deg); /* IE 9 */
  -webkit-transform: rotate(300deg); /* Chrome, Safari, Opera */
  transform: rotate(300deg);
}

.rotate-315 {
  -ms-transform: rotate(315deg); /* IE 9 */
  -webkit-transform: rotate(315deg); /* Chrome, Safari, Opera */
  transform: rotate(315deg);
}

.rotate-330 {
  -ms-transform: rotate(330deg); /* IE 9 */
  -webkit-transform: rotate(330deg); /* Chrome, Safari, Opera */
  transform: rotate(330deg);
}
