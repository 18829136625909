/* ========================================================================== *\
   Objects -> Lists ($objects-lists)
\* ========================================================================== */

/**
 * Bare Lists
 *
 * Use to remove default list styles from <ol> and <ul> elements.
 */

.list-bare {
  list-style: none;

  li {
    margin-left: 0;
  }
}

/**
 * Reset Lists
 *
 * Use to remove all list styles from <ol> and <ul> elements.
 */

.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-left: 0;
  }
}

/**
 * Inline Lists
 *
 * Create inline-style list items with <ol> and <ul> elements.
 */

.list-inline {
  list-style: none;
  padding: 0;

  li {
    display: inline;
    margin-left: 0;
  }
}

/**
 * Delimited lists
 * Hat tip to @csswizardry for the technique.
 *
 * @link http://cbrac.co/1RvOzMU
 */

.list-inline-delimited {
  > li + li {
    &:before {
      content: @list-inline-delimiter-content;
    }
  }
}
