.br0      { border-radius: 0 !important; }
.br---    { border-radius: 2px !important; }
.br--     { border-radius: 6px !important; }
.br-      { border-radius: 10px !important; }
.br       { border-radius: 16px !important; }
.br\+     { border-radius: 22px !important; }
.br\+\+   { border-radius: 30px !important; }
.br\+\+\+ { border-radius: 40px !important; }

// - TOP
.br-t0      { border-top-left-radius: 0 !important; border-top-right-radius: 0 !important; }
.br-t---    { border-top-left-radius: 2px !important; border-top-right-radius: 2px !important; }
.br-t--     { border-top-left-radius: 6px !important; border-top-right-radius: 6px !important; }
.br-t-      { border-top-left-radius: 10px !important; border-top-right-radius: 10px !important; }
.br-t       { border-top-left-radius: 16px !important; border-top-right-radius: 16px !important; }
.br-t\+     { border-top-left-radius: 22px !important; border-top-right-radius: 22px !important; }
.br-t\+\+   { border-top-left-radius: 30px !important; border-top-right-radius: 30px !important; }
.br-t\+\+\+ { border-top-left-radius: 40px !important; border-top-right-radius: 40px !important; }

// - RIGHT
.br-r0      { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; }
.br-r---    { border-top-right-radius: 2px !important; border-bottom-right-radius: 2px !important; }
.br-r--     { border-top-right-radius: 6px !important; border-bottom-right-radius: 6px !important; }
.br-r-      { border-top-right-radius: 10px !important; border-bottom-right-radius: 10px !important; }
.br-r       { border-top-right-radius: 16px !important; border-bottom-right-radius: 16px !important; }
.br-r\+     { border-top-right-radius: 22px !important; border-bottom-right-radius: 22px !important; }
.br-r\+\+   { border-top-right-radius: 30px !important; border-bottom-right-radius: 30px !important; }
.br-r\+\+\+ { border-top-right-radius: 40px !important; border-bottom-right-radius: 40px !important; }

// - BOTTOM
.br-b0      { border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important; }
.br-b---    { border-bottom-left-radius: 2px !important; border-bottom-right-radius: 2px !important; }
.br-b--     { border-bottom-left-radius: 6px !important; border-bottom-right-radius: 6px !important; }
.br-b-      { border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important; }
.br-b       { border-bottom-left-radius: 16px !important; border-bottom-right-radius: 16px !important; }
.br-b\+     { border-bottom-left-radius: 22px !important; border-bottom-right-radius: 22px !important; }
.br-b\+\+   { border-bottom-left-radius: 30px !important; border-bottom-right-radius: 30px !important; }
.br-b\+\+\+ { border-bottom-left-radius: 40px !important; border-bottom-right-radius: 40px !important; }

// - LEFT
.br-l0      { border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important; }
.br-l---    { border-top-left-radius: 2px !important; border-bottom-left-radius: 2px !important; }
.br-l--     { border-top-left-radius: 6px !important; border-bottom-left-radius: 6px !important; }
.br-l-      { border-top-left-radius: 10px !important; border-bottom-left-radius: 10px !important; }
.br-l       { border-top-left-radius: 16px !important; border-bottom-left-radius: 16px !important; }
.br-l\+     { border-top-left-radius: 22px !important; border-bottom-left-radius: 22px !important; }
.br-l\+\+   { border-top-left-radius: 30px !important; border-bottom-left-radius: 30px !important; }
.br-l\+\+\+ { border-top-left-radius: 40px !important; border-bottom-left-radius: 40px !important; }

// - TOP LEFT
.br-tl0      { border-top-left-radius: 0 !important; }
.br-tl---    { border-top-left-radius: 2px !important; }
.br-tl--     { border-top-left-radius: 6px !important; }
.br-tl-      { border-top-left-radius: 10px !important; }
.br-tl       { border-top-left-radius: 16px !important; }
.br-tl\+     { border-top-left-radius: 22px !important; }
.br-tl\+\+   { border-top-left-radius: 30px !important; }
.br-tl\+\+\+ { border-top-left-radius: 40px !important; }

// - TOP RIGHT
.br-tr0      { border-top-right-radius: 0 !important; }
.br-tr---    { border-top-right-radius: 2px !important; }
.br-tr--     { border-top-right-radius: 6px !important; }
.br-tr-      { border-top-right-radius: 10px !important; }
.br-tr       { border-top-right-radius: 16px !important; }
.br-tr\+     { border-top-right-radius: 22px !important; }
.br-tr\+\+   { border-top-right-radius: 30px !important; }
.br-tr\+\+\+ { border-top-right-radius: 40px !important; }

// - BOTTOM RIGHT
.br-br0      { border-bottom-right-radius: 0 !important; }
.br-br---    { border-bottom-right-radius: 2px !important; }
.br-br--     { border-bottom-right-radius: 6px !important; }
.br-br-      { border-bottom-right-radius: 10px !important; }
.br-br       { border-bottom-right-radius: 16px !important; }
.br-br\+     { border-bottom-right-radius: 22px !important; }
.br-br\+\+   { border-bottom-right-radius: 30px !important; }
.br-br\+\+\+ { border-bottom-right-radius: 40px !important; }

// - BOTTOM LEFT
.br-bl0      { border-bottom-left-radius: 0 !important; }
.br-bl---    { border-bottom-left-radius: 2px !important; }
.br-bl--     { border-bottom-left-radius: 6px !important; }
.br-bl-      { border-bottom-left-radius: 10px !important; }
.br-bl       { border-bottom-left-radius: 16px !important; }
.br-bl\+     { border-bottom-left-radius: 22px !important; }
.br-bl\+\+   { border-bottom-left-radius: 30px !important; }
.br-bl\+\+\+ { border-bottom-left-radius: 40px !important; }
