.permission-group {
  .to-rem ( padding, 20 );
  .to-rem ( margin-bottom, 48 );
  .to-rem ( border, 1, solid #CCC );
  background-color: @color-white;

  &:last-child {
    .to-rem ( margin-bottom, 24 );
  }
}

.permission-tr {
  &.disabled {
    td {
      color: #BBB;
      background-color: #F4F4F4;
    }
  }
}
