/* ========================================================================== *\
   Base -> Print ($base-print)
\* ========================================================================== */

/**
 * Print styles extracted from HTML5-Boilerplate
 *
 * @link http://cbrac.co/1RvNULl
 */

@media print {
  /* 1. Black prints faster - http://cbrac.co/XvusCs */
  * {
    color: @print-text-color !important; /* 1 */
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  @page {
    margin: 0.5cm;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  /* Don’t show links for images, or javascript/internal links */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  blockquote,
  pre {
    border: 1px solid @color-gray-dark;

    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  /* 1. http://cbrac.co/Q6s1o2 */
  thead {
    display: table-header-group; /* 1 */
  }
}
