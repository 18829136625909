nav.main-nav {
  > ul {
    ul {
      z-index: 1000;
    }
  }
}

// - LANGUAGES
.selected-lang,
.language-selector-flag {
  position: relative;
  .to-rem ( width, 24 );
  .to-rem ( height, 24 );
  .to-rem ( top, 5 );

  background-image: url('/assets/graphics/flag-icons.png');
  background-repeat: no-repeat;

  &.english { .to-rem ( background-position, -213 -208 ); }
  &.german  { .to-rem ( background-position, -213 -78 ); }
}

.language-selector {
  position: absolute;
  .to-rem ( padding, 0 8 8 8 );
  .to-rem ( border-radius, 0 0 4 4 );

  li {
    margin-left: 0;

    text-transform: uppercase;
  }
}

label[for="cb-select-language"] {
  display:inline-block;
  .to-rem ( margin, 0 3 );
  .to-rem ( padding, 0 5 );
}

#cb-select-language {
  display: none;

  &:checked + .language-selector {
    display: block;
  }
  & + .language-selector {
    display: none;
  }
}
