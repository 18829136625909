//@occupational-competence
.oc-initial-form {
  .to-rem ( padding, 16 );
  .to-rem ( border, 1, solid #DDD );
  background-color: @color-white;

  .renewal-settings {
    .var-name {
      .to-rem ( width, 190 );
      display: inline-block;
    }

    .number {
      .to-rem ( width, 62 );
      display: inline-block;
    }

    .unit {
      display: inline-block;
    }
  }
}

.oc-nav {
  .to-rem ( height, 70 );
  .to-rem ( margin-bottom, 20 );
  .to-rem ( padding, 16 );
  .to-rem ( border-radius, 8 );
  .to-rem ( border, 1, solid #DDD );

  background-color: #FFF;

  li {

    cursor: pointer;

    .number {
      float: left;
      .to-rem ( padding, 11 10 9 10 );
      .to-rem ( width, 38 );
      .to-rem ( margin-top, -1 );
      .to-rem ( border-radius, 30 );

      .to-rem ( border, 1, solid #308E52 );
      background: #62D28F;
      background: -moz-radial-gradient( top left, circle cover,  #62D28F 0%, #329052  100%);
      background: -webkit-radial-gradient( top left, circle cover,  #62D28F 0%,#329052  100%);
      background: radial-gradient(circle at top left,  #62D28F 0%,#329052  100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62D28F', endColorstr='#329052 ',GradientType=1 );

      box-shadow: inset -3px -3px 0 rgba(0, 0, 0, 0.07);

      color: @color-white;

      text-align: center;
    }

    &.tab-button {
      float: left;
      .to-rem ( margin, 0 );

      &:not(:first-child) {
        border-left: none;
      }

      .section-name-status {
        .to-rem ( padding, 10 0 10 25 );
        .to-rem ( height, 36 );
        .to-rem ( border-radius, 0 17 17 0 );
        .to-rem ( margin-left, 20 );

        .to-rem ( border-width, 1 1 1 0 );
        border-style: solid;
        border-color: #E0E0E0;

        background-color: @color-white;

        box-shadow: inset -3px -3px 1px rgba(0, 0, 0, 0.04);

        &.on {
          background-color: @color-white;
          border-color: #49B06F;

          .section-name {
            color: #49B06F;
          }
        }

        &.off {
          background-color: #F7F7F7;
          border-color: #E0E0E0;

          .section-name {
            color: #BBB;
          }
        }

        .section-name {
          float: left;
        }

        .section-status {
          float: right;
          .to-rem ( width, 26 );
          .to-rem ( margin-top, -5 );
          .to-rem ( margin-right, 3 );

          .fa-check-circle {
            color: #7B7;
          }
        }
      }
    }

    &.not-used {
      .number {
        .to-rem ( border, 1, solid #BBB );
        background: #CCC;
      }

      .section-name-status {
        .section-name {
          color: #DDD;
        }

        &.off {
          .section-name {
            color: #DDD;
          }
        }
      }
    }

    &:not(:last-child) {
      &.tab-button {
        .section-name-status {
          .to-rem ( margin-right, 5 );
        }
      }
    }
  }
}

.ocb-section {
  main {
    position: relative;
  }

  .no-edit-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.oc-titles {
  float: left;
  width: 65%;
}

.oc-attempt-user-data {
  float: right;
  display: table;
  .to-rem ( width, 320 );

  td {
    .to-rem ( border-bottom, 1, solid #F3F3F3 );
    color: #094C82;

    .to-rem ( font-size, 12 );
    text-transform: uppercase;

    &:first-child {
      background-color: #FFF;
      color: #111;
    }

    &:nth-child(3) {
      border-left: none;
    }

    &.editing {
      .to-rem ( padding, 3 3 3 10 );

      select {
        margin: 0;
      }
    }
  }

  tr {
    &:nth-child(2),&:nth-child(3) {
      td {
        &:nth-child(2) {
          border-right: none;
        }
      }
    }
  }

  .edit-mentor-assessor-btn {
    background: none;
    border: none;
    outline: none;
  }
}

.learning-materials {
  .to-rem ( padding, 30 );
  .to-rem ( border-radius, 8 );

  background-color: @color-white;
  .to-rem ( border, 1, solid #DDD );
}

.save-btn {
  border: none;

  background-color: @color-white;
  color: #999;

  &:hover {
    color: @color-black;
  }
}

.selected-mentor-assessor {
  tr {
    .to-rem ( border, 1, solid #DDD );

    td {
      &:first-child {
        .to-rem ( width, 160 );
        background-color: #F3F3F3;
        text-align: right;
        color: #AAA;
      }
    }
  }
}

.oc-attachments {
  label {
    .to-rem ( margin-top, 7 );
    .to-rem ( margin-left, 5 );
    .to-rem ( margin-right, 3 );
    float: left;

    color: #AAA;

    .to-rem ( font-size, 14 );
  }

  .oc-attachment {
    display: inline-block;
    .to-rem ( padding, 2 3 3 3 );
    .to-rem ( margin, 2 2 0 0 );
    .to-rem ( border-radius, 3 );
    .to-rem ( border, 1, solid #DDD );
    background-color: #EEE;

    a {
      .to-rem ( margin-right, 3 );

      color: #999;

      .to-rem ( font-size, 12 );
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    button {
      .to-rem ( padding, 2 );
      border: none;

      background: none;
      color: #999;

      .to-rem ( font-size, 12 );

      &:hover {
        color: #E00;
      }
    }
  }

  &.oc-attachments-disabled {
    background-color: #E9E9E9 !important;
    border-color: #D5D5D5 !important;
  }
}

.practice-runs,
.practical-assessments {
  .oc-single-item {
    border-style: solid;
    .to-rem ( border-width, 1 );
    border-color: #3563ba;
  }

  .specific-task-header {
    border-style: solid;
    .to-rem ( border-width, 9 1 1 1 );
    border-color: #3563BA;
  }

  .generic-task {
    .to-rem ( border-width, 9 1 1 1 );
    border-color: #C6D1E4;
  }

  .oc-date {
    .to-rem ( width, 116 );
  }

  .oc-notes {
    resize: none;
  }

  td {
    position: relative;
  }

  button {
    &.remove-assessment {
      position: absolute;
      top: 0;
      .to-rem ( right, -26 );
      z-index: 10;
      .to-rem ( padding, 8 );
      .to-rem ( border-width, 1 1 1 0 );
      .to-rem ( border-radius, 0 9 9 0 );
      clip: rect(0px,91px,34px,65px);
      border-style: solid;

      color: @color-black;
      background-color: @color-white;
      border-color: #DDD;

      transition-property: clip|right;
      transition-duration: .5s;

      &:hover {
        .to-rem ( right, -90 );
        clip: rect(0px,91px,34px,1px);

        background-color: #C00;
        color: @color-white;
      }
    }
  }
}

.practice-run-container {
  .fixed-1 {
    float: left;
    .to-rem ( width, 116 );
    position: relative;
    z-index: 2;
  }
  .fluid {
    .to-rem ( margin-left, -116 );
    .to-rem ( padding-left, 120 );
    position: relative;
    z-index: 1;

    input {
      width: 100%;
    }
  }
  .specific-task {
    .fluid {
      .to-rem ( margin-right, 0 );
      .to-rem ( padding-right, 0 );
    }
  }
  .generic-task {
    .fluid {
      .to-rem ( margin-right, -280 );
      .to-rem ( padding-right, 284 );
    }
  }
  .fixed-2 {
    float: right;
    .to-rem ( width, 280 );
    position: relative;
    z-index: 2;
  }
  .nh-radio-group {
    .to-rem ( margin-top, 6 );
  }
}

.practical-assessments-container {
  .fixed-1 {
    float: left;
    width: 116px;
    position: relative;
    z-index: 2;
  }
  .fluid {
    .to-rem ( margin-left, -116 );
    .to-rem ( padding-left, 120 );
    .to-rem ( margin-right, -224 );
    .to-rem ( padding-right, 228 );
    position: relative;
    z-index: 1;

    input {
      width: 100%;
    }
  }
  .fixed-2 {
    float: right;
    .to-rem ( width, 224 );
    position: relative;
    z-index: 2;
  }
  .nh-radio-group {
    .to-rem ( margin-top, 6 );
  }
}

.OC-table-breaker {
  td {
    background-color: transparent !important;
  }
}

.oc-td-attachments {
  .oc-attachments {
    .to-rem ( min-height, 40 );
    .to-rem ( padding, 5 );

    .to-rem ( border, 1, solid #e2e2e2 );
  }
}

.oc-add-attachment-btn {
  // border-radius: 0;
  // .to-rem ( padding-top, 11 );
  // .to-rem ( padding-bottom, 12 );
  //
  // border: none;
  //
  // background-color: #6B6;
  // border-color: #7C7;
  // color: @color-white;
  .to-rem ( margin-left, 5 );
  .to-rem ( margin-top, 3 );
}

.generic-assessment {
  td {
    .to-rem ( padding, 10 );
    background-color: #048;
    color: @color-white;
  }

  .remove-btn {
    background: none;
    border: none;

    color: #7BE;

    &:hover {
      color: #FFF;
    }
  }
}

.guidance-docs {
  header * {
    .to-rem ( font-size, 16 );
  }

  .to-rem ( padding, 20 );
  .to-rem ( margin-bottom, 20 );
  .to-rem ( border-top, 9, solid #E0E0E0 );

  background-color: @color-white;

  ul {
    margin: 0;

    li {
      margin: 0;

      a {
        .to-rem ( font-size, 14 );
      }
    }
  }
}

.specific-assessment {
  .remove-btn {
    background: none;
    border: none;

    color: #7BE;

    &:hover {
      color: #FFF;
    }
  }
}

.OC-player {
  .specific-task-header {
    td {
      .to-rem ( padding, 10 0 12 14 );

      background-color: #18408A;
      color: @color-white;

      .specific-task-title {
        display: inline;
        float: left;
        .to-rem ( margin, 10 10 10 0 );
      }
    }
  }
  .specific-task-description {
    td {
      background-color: #EEF4FF;
      color: @color-black;
    }
  }
  .specific-task-guidance {
    td {
      .to-rem ( border-top, 1, dotted #c6d1e6 );
      background-color: #EEF4FF;
      color: @color-black;
    }
  }

  .inactive {
    border-color: #3563BA #AAA;

    .specific-task-header {
      td {
        background-color: #DDD;
        color: #BBB;
      }
    }
    .specific-task-description {
      td {
        background-color: #EEE;
        color: #BBB;
      }
    }
    .specific-task-guidance {
      td {
        .to-rem ( border-top, 1, solid #CCC );
        background-color: #EEE;
        color: #BBB;

        .headings({
          color: #BBB;
        });
      }
    }
    .nh-radio-btn-on {
      background-color: #BDB;
    }
    .nh-radio-btn-off {
      background-color: #E4E4E4;
    }
    .row-1 td, .row-2 td, .row-3 td {
      background-color: #EEE;
    }
    input[type="text"],
    .oc-attachments,
    textarea {
      background-color: #EEE;
    }
    .oc-add-attachment-btn {
      .neutral-btn;

      &:hover {
        background-color: #EEE;
      }
    }
  }

  .generic-task-header {
    td {
      background-color: #EEF4FF;
      color: @color-black;
    }
  }
}

.task-breaker {
  .to-rem ( height, 20 );
}




.no-assessments {
  tr:first-child {
    td {
      background-color: #DDD;
    }
  }
}

.add-guidance-btn {
  background: none;
  border: none;
  color: #069;
}

.specific-assessment-guidance {
  .to-rem ( padding, 3 5 7 5 );
}

.specific-assessment-guidance-doc {
  display: inline-block;
  .to-rem ( padding, 3 4 4 6 );
  .to-rem ( margin, 2 2 0 0 );
  .to-rem ( border-radius, 3 );
  .to-rem ( border, 1, solid #059 );
  background-color: @color-white;

  a {
    .to-rem ( margin-right, 3 );
    color: #059;
    .to-rem ( font-size, 12 );
    text-decoration: none;
  }

  button {
    .to-rem ( padding, 2 );
    border: none;
    background: none;
    color: #059;
    .to-rem ( font-size, 12 );

    &:hover {
      color: #E00;
    }
  }
}

textarea.auto-height {
  .to-rem ( padding, 10 );
  resize: none;
  overflow: hidden;
  height: inherit;
}

.lock-watermark {
  position: absolute;

  left: 50%;
  .to-rem ( margin-left, -112 );
  .to-rem ( padding, 10 );
  .to-rem ( border-radius, 20 );
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.8);

  box-shadow: 0px 8px 10px rgba(0,0,0,0.1);

  .coloured-panel {
    .to-rem ( padding, 50 60 );
    .to-rem ( border-radius, 20 );
    .to-rem ( border, 10, solid rgba(200, 0, 0, 0.5) );
    background-color: rgba(200, 0, 0, 0.5);
    i {
      .to-rem ( font-size, 100 );
      color: rgba(50,0,0,0.7);
    }
  }
}

#s2-lock-watermark {
  .to-rem ( top, 215 );
}

#s3-lock-watermark {
  .to-rem ( top, 325 );
}

#s4-lock-watermark {
  .to-rem ( top, 325 );
}

.oc-building,
.oc-duplicating {
  .to-rem ( margin-top, 70 );

  color: #CCC;

  .to-rem ( font-size, 15 );
}

.oc-version {
  .to-rem ( border-radius, 4 );
  .to-rem ( margin-left, 10 );
  .to-rem ( padding, 4 );

  background-color: #BBB;
  color: #FFF;

  .to-rem ( font-size, 10 );
}
