// - MIXINS

.button ( @bg-colour: #222, @border-colour: #000, @text-colour: #FFF, @text-shadow-colour: #000,
          @bg-hover-colour: #F93, @border-hover-colour: #C60, @text-hover-colour: #FFF, @text-shadow-hover-colour: #C60 ) {
  display: inline-block;

  border-color: @border-colour;
  background-color: @bg-colour;
  color: @text-colour;

  font-weight: 700;
  // font-size: 10pt;

  text-decoration: none;

  cursor: pointer;

  &:hover {
    border-color: @border-hover-colour;
    background-color: @bg-hover-colour;
    color: @text-hover-colour;
    .to-rem ( text-shadow, 1 1, @text-shadow-hover-colour );
  }

  &:active {
    //.to-rem ( border-top-width, 4 );
    //.to-rem ( border-bottom-width, 1 );
  }
}

// - RULES

.gen-btn {
  .to-rem ( padding, 6 5 4 5 );
  .to-rem ( border-radius, 4 );
  .to-rem ( border-width, 1 1 4 1 );
  border-style: solid;

  text-decoration: none;
}

.positive-btn {
  .button ( @pos-btn-bg-colour, @pos-btn-border-colour, @pos-btn-text-colour, @pos-btn-ts-colour,
            @pos-btn-hover-bg-colour, @pos-btn-hover-border-colour, @pos-btn-hover-text-colour, @pos-btn-hover-ts-colour );
}

.neutral-btn {
  .button ( @neu-btn-bg-colour, @neu-btn-border-colour, @neu-btn-text-colour, @neu-btn-ts-colour,
            @neu-btn-hover-bg-colour, @neu-btn-hover-border-colour, @neu-btn-hover-text-colour, @neu-btn-hover-ts-colour );
}

.disabled-btn {
  .button ( @neu-btn-bg-colour, @neu-btn-border-colour, lighten(@neu-btn-text-colour, 25%), @neu-btn-ts-colour,
            @neu-btn-hover-bg-colour, @neu-btn-hover-border-colour, lighten(@neu-btn-text-colour, 25%), @neu-btn-hover-ts-colour );
  cursor: no-drop !important;
}

.negative-btn {
  .button ( @neg-btn-bg-colour, @neg-btn-border-colour, @neg-btn-text-colour, @neg-btn-ts-colour,
            @neg-btn-hover-bg-colour, @neg-btn-hover-border-colour, @neg-btn-hover-text-colour, @neg-btn-hover-ts-colour );
}

// - A BUTTON BANK IS WHEN ICONS / BUTTONS ARE TOGETHER AND IT MAKES SENSE
// - TO GROUP THEM BY NUDGING THEM FLUSH AND REMOVING BORDERS AND BORDER RADII
// - THESE MUST BE ALONE IN A CONTAINER, SO IF THEY'RE NOT JUST WRAP IN A DIV

.threeD-btn {
  .to-rem ( border-bottom-width, 4 );

  &:hover {
    .to-rem ( border-bottom-width, 4 );
  }
}

.btn-bank-host {
  white-space: nowrap;
}

.btn-bank {
  //float: left;
  display: inline-block;
  .to-rem ( border-bottom-width, 4 );
  .to-rem ( min-width, 15 );
  .to-rem ( padding, 6 );

  text-align: center;

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    border-left-width: 0;
  }

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }
}

.lone-bank-btn {
  .to-rem ( padding, 6 );
  .to-rem ( min-width, 15 );

  text-align: center;
}

.link-bank {
  float: left;
  display: block;
  border: none;
  .to-rem ( min-width, 15 );
  .to-rem ( padding, 6 );

  background: none;
  color: @btn-bank-colour;
  text-align: center;

  &:not(:first-child) {
    margin-left: 0;
  }

  &:not(:last-child) {
    margin-right: 0;
  }

  &:hover {
    color: @btn-bank-hover-colour;
  }
}

.nh-radio-group {
  display: inline;

  .nh-radio-btn {
    .to-rem ( border-radius, 20 );
    .to-rem ( padding, 10 );

    // .to-rem ( border, 2, solid @color-white );
    border: none;
    color: @color-white;
  }

  .nh-radio-btn-off {
    background-color: #CCC;
    color: #8c8c8c;

    &:hover {
      background-color: #F90;
      color: @color-white;
    }
  }

  .nh-radio-btn-on {
    background-color: #55a955;
    box-shadow: 2px 2px 1px rgba(0,0,0,0.1);
    color: @color-white;

    &:hover {
      background-color: #7C7;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: @heading-font, Arial, Helvetica;
}

p {
  .to-rem ( line-height, 28 );
}

.gen-highlight {
  color: @colour-gen-highlight;
}

.gen-highlight-boxed {
  .to-rem ( padding, 5 10 );

  background-color: @colour-gen-highlight;
  color: @color-white;
}

.flash-data {
  position: fixed;
  .to-rem ( top, -65 );
  .to-rem ( left, 0 );
  .to-rem ( padding, 20 );
  width: 100%;
  z-index: @Z-flash-data;
  .to-rem ( border-bottom-width, 4 );
  border-bottom-style: solid;

  color: @color-white;

  font-weight: bold;
  text-align: center;

  .content {
    .to-rem ( line-height, 22 );

    button {
      .to-rem ( padding, 2 7 );
      .to-rem ( border-radius, 4 );
      .to-rem ( border, 1, solid #383 );
      background-color: #7C7;
      color: #FFF;
    }
  }

  .close-btn {
    float: right;
    .to-rem ( border-radius, 20 );
    .to-rem ( width, 20 );
    .to-rem ( padding, 2 0 1 0 );

    color: @color-white;

    cursor: pointer;

    &:hover {
      color: #CA0C0C;
      background-color: @color-white;
    }

    &:active {
      color: @color-white;
      background-color: #800;
    }
  }

  &.positive {
    background-color: rgba(119, 204, 119, 1);
    border-color: rgb(80, 164, 80);
    color: #CFC;
    a {
      color: @color-white;
    }
  }

  &.negative {
    background-color: rgba(200, 0, 0, 1);
    border-color: rgb(150, 0, 0);
    color: #F99;
    a {
      color: @color-white;
    }
  }

  &.neutral {
    background-color: rgb(255, 152, 0, 1);
    border-color: rgb(232, 137, 0);
    color: #FFDBA8;
    a {
      color: @color-white;
    }
  }
}

// - ICONS

.watermark-icon {
  color: @colour-watermark;

  .to-rem ( font-size, 110 );
}

.confirmation-icon {
  color: @colour-confirmation;

  .to-rem ( font-size, 110 );
}

.error-icon {
  color: @colour-error;

  .to-rem ( font-size, 110 );
}

.status-icon {
  .to-rem ( font-size, 20 );
}

.passed {
  .fa-circle {
    //color: rgba(40, 173, 101, 0.8);
    //text-shadow: -3px -3px 0px @color-white, 0 0 0 rgba(0,0,0,0.2), 1px 4px 6px @color-white;
    color: #28AD65;
  }
}

.red-icon {
  background-color: #E81F00;
}

.yellow-icon {
  background-color: #FC0;
}

.green-icon {
  background-color: #28AD65;
}

.white-icon {
  background-color: #F7F7F7;
  color: #555;
}

.circle-icon, .xs-circle-icon, .sm-circle-icon, .md-circle-icon, .lg-circle-icon, .xl-circle-icon {
  display: inline-block;
  
  &.expired, &.expiring, &.not-started {
    .fa-layers-text {
      font-family: Oswald;
    }
  }
  
  &.expired, &.failed {
    .fa-circle {
      color: #F00;
    }
    .fa-layer-text {
      color: #FFF;
      font-weight: 400;
    }
  }

  &.not-started {
    .fa-circle {
      color: #FFF;
      &.border {
        color: #CCC;
      }
    }
    .fa-layers-text {
      color: #777;
      font-weight: 400;
    }
  }

  &.incomplete {
    .fa-circle {
      color: #FFC316;
    }
    .border {
      color: darken(#FFC316, 5%) !important;
    }
    .icon {
      color: #FFF;
    }
  }

  &.locked {
    .fa-circle {
      color: #BBB;
    }
    .border {
      color: darken(#BBB, 5%) !important;
    }
    .icon {
      color: #FFF;
    }
  }
  
  &.passed {
    .fa-circle {
      color: #093;
    }
    .border {
      color: darken(#093, 5%) !important;
    }
    .icon {
      color: #FFF;
    }
  }
}

.oc-tab-th-icon {
  transform: scale(0.6);

  .icon {
    color: #FFF;
  }

  &.oc-tab-th-icon-1 {
    .fa-circle {
      color: #AAA;
    }
  }

  &.oc-tab-th-icon-2 {
    .fa-circle {
      color: #999;
    }
  }

  &.oc-tab-th-icon-3 {
    .fa-circle {
      color: #888;
    }
  }

  &.oc-tab-th-icon-4 {
    .fa-circle {
      color: #777;
    }
  }
}

.oc-tab-td-1 {
  background-color: #F7F7F7;
}

.oc-tab-td-2 {
  background-color: #F3F3F3;
}

.oc-tab-td-3 {
  background-color: #EFEFEF;
}

.oc-tab-td-4 {
  background-color: #EBEBEB;
}

.circle-icon {
  transform: scale(0.7);
}

.xs-circle-icon {
  transform: scale(0.5);
}

.sm-circle-icon {
  transform: scale(0.6);
}

.md-circle-icon {
  transform: scale(0.7);
}

.lg-circle-icon {
  transform: scale(0.8);
}

.xl-circle-icon {
  transform: scale(0.9);
}

// - POPUP

.popup {
  background-color: #EEE;

  .popup-title {
    float: left;
    width: 100%;
    .to-rem ( margin-right, -60 );
    .to-rem ( margin-bottom, 3 );

    .to-rem ( font-size, 18 );
  }

  .popup-close-btn {
    .to-rem ( padding, 3 5 2 5 );
    .to-rem ( margin-right, -5 );
    .to-rem ( border-radius, 3 );
    float: right;
    border: none;

    background-color: transparent;

    .to-rem ( font-size, 12 );
    .to-rem ( letter-spacing, 1 );
    text-transform: uppercase;

    &:hover {
      background-color: #861D00;
      color: #FFF;
    }
  }

  .popup-centred-btns {
    display: flex;
    justify-content: center;
  }

  .popup-content {
    width: 100%;

    .choice-with-explanation {
      .to-rem ( margin-bottom, 16 );

      p {
        .to-rem ( border-radius, 3 3 0 0 );
        .to-rem ( margin-bottom, 0 );
        .to-rem ( padding, 8 );

        background-color: @color-white;
        .to-rem ( border, 1, solid #CCC );

        border-bottom: none;
      }

      button {
        .to-rem ( padding, 8 );
        width: 100%;
        .to-rem ( border-radius, 0 0 3 3 );

        &.negative-btn {
          background-color: #D00F0F;
        }
      }
    }

    .choice-or {
      .to-rem ( margin, 20 );

      .to-rem ( font-size, 24 );
      font-weight: bold;
      text-align: center;
    }
  }
}

// - / POPUP

// - MISC
.disabled-viewing {
  .to-rem ( margin-top, 6 );
  .to-rem ( padding, 9 );
  .to-rem ( min-height, 40 );
  .to-rem ( border, 1, solid #e2e2e2 );
  background-color: #F9F9F9;

  p:last-child {
    margin-bottom: 0;
  }
}

// - FIND A BETTER PLACE FOR ITEMS IN HERE ASAP
.quick-help {
  position: relative;

  background: none;
  border: none;
  color: @dark-link-colour;

  cursor: pointer;

  .quick-help-text {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    .to-rem ( width, 200 );
    .to-rem ( padding, 8 );
    .to-rem ( border-radius, 8 );
    .to-rem ( border, 2, solid #FFF );

    color: @color-white;
    background-color: #7C7;

    .to-rem ( font-size, 14 );
    text-align: left;

    .to-rem ( box-shadow, 0 0 8, rgba(0, 0, 0, 0.15));

    &.below {
      .to-rem ( top, 26 );
      &::before {
        .to-rem ( top, -21 );
        border-color: transparent transparent #FFF transparent;
      }
      &::after {
        .to-rem ( top, -16 );
        border-color: transparent transparent #7C7 transparent;
      }
    }

    &.right {
      .to-rem ( left, -24 );
      &::before {
        .to-rem ( left, 20 );
      }
      &::after {
        .to-rem ( left, 22 );
      }
    }

    &.above {
      .to-rem ( bottom, 26 );
      &::before {
        .to-rem ( bottom, -21 );
        border-color: #FFF transparent transparent transparent;
      }
      &::after {
        .to-rem ( bottom, -16 );
        border-color: #7C7 transparent transparent transparent;
      }
    }

    &.left {
      .to-rem ( right, -24 );
      &::before {
        .to-rem ( right, 19 );
      }
      &::after {
        .to-rem ( right, 21 );
      }
    }

    p {
      margin-bottom: 0;

      .to-rem ( line-height, 20 );
      .to-rem ( letter-spacing, 0.1 );
      .to-rem ( font-size, 13 );
      font-weight: normal;
    }

    &::before {
      content: ' ';
      position: absolute;
      z-index: 200;
      .to-rem ( width, 20 );
      .to-rem ( height, 20 );
      .to-rem ( border-width, 10 );
      border-style: solid;
    }

    &::after {
      content: ' ';
      position: absolute;
      z-index: 201;
      .to-rem ( width, 16 );
      .to-rem ( height, 16 );
      .to-rem ( border-width, 8 );
      border-style: solid;
    }
  }
}

.flash-button-on {
  background-color: #FF4A4A !important;
  border-color: #DF2A2A !important;
  text-shadow: 1px 1px #DF2A2A;
}

.page-header-with-icon {
  .to-rem ( border-bottom, 3, solid #F2F2F2 );
  background-color: @color-white;
  color: #4483AC;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);

  span {
    display: inline-block;
    .to-rem ( margin, 14 );
  }

  .gen-btn {
    .to-rem ( font-size, 16 );
    .to-rem ( margin, 10 );
  }
}

.section-header-with-icon {
  color: #4483AC;
}

.page-subheader-with-icon {
  .to-rem ( padding, 14 );
  .to-rem ( border-left, 4, solid #6C95E0 );
  background-color: #F5F9FF;
  color: #6C95E0;
}

.page-subheader-color {
  color: #6C95E0;
}

.info-box {
  .to-rem ( padding, 18 );
  .to-rem ( margin, 16 0 );

  .to-rem ( border-width, 1 );
  border-style: solid;

  text-align: center;

  &.negative {
    background-color: #FEE;
    border-color: #A00;
    color: #A00;
  }
}

.upload-section {
  .to-rem ( padding, 16 );

  .to-rem ( border, 1, solid #DDD );

  background-color: @color-white;
}

.section {
  .to-rem ( padding, 16 );

  .to-rem ( border-bottom, 3, solid #F2F2F2 );

  background-color: @color-white;

  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
}

.group {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.lh--   { line-height: 0.5rem !important; }
.lh-    { line-height: 0.75rem !important; }
.lh     { line-height: 1rem !important; }
.lh\+   { line-height: 1.25rem !important; }
.lh\+\+ { line-height: 1.5rem !important; }

.mt--- { margin-top: 2px !important; }
.mr--- { margin-right: 2px !important; }
.mb--- { margin-bottom: 2px !important; }
.ml--- { margin-left: 2px !important; }

.pos-rel {
  position: relative !important;
}

i, svg {
  &.fa-check.mini-check {
    position: absolute;
    .to-rem ( bottom, 1 );
    .to-rem ( right, 1 );

    .to-rem ( font-size, 7 );
  }
}

.positive-btn {
  i, svg {
    &.fa-check.mini-check {
      display: block;
    }
  }
}

.neutral-btn {
  i, svg {
    &.fa-check.mini-check {
      display: none;
    }
  }
}

.negative-btn {
  i, svg {
    &.fa.fa-check.mini-check {
      display: none;
    }
  }
}

.not-a-button {
  border: none;
  background: none;
}
// - / MISC
