.itr-cpd-files {
  > td {
    .to-rem ( padding, 0 ) !important;

    table {
      margin-bottom: 0 !important;

      td {
        .to-rem ( border-width, 1 0 ) !important;
        .to-rem ( padding-left, 30 ) !important;

        background-color: #EAF6FF;
      }
    }
  }
}

.itr-cpd-separator {
  background-color: #D8E8F8 !important;
}
