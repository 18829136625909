form,
.pseudo-form {

  ul {
    list-style: none;

    &:not(.no-li-mb) {
      li {
        .to-rem ( margin, 0 0 10 0 );
      }
    }

    input,
    select:not(.custom-dd) {
      width: 100%;
      .to-rem ( padding, 5 );
      .to-rem ( font-size, 16 );

    }

    label {

      &.std-label {
        display: inline-block;
        .to-rem ( margin, 0 10 0 1 );

        color: @std-label-colour;

        .to-rem ( font-size, 16 );
        text-align: left;

      }

      &.adjoined-label {
        .to-rem ( padding, 13 12 12 12 );
        background-color: #666;
        color: #D5D5D5;

        &.before {
          .to-rem ( margin, 4 0 0 0 );
        }

        &.after {
          position: relative;
          .to-rem ( left, -6 );
          .to-rem ( margin, 4 4 0 0 );
        }
      }

      &:not(.cb-label) {
        //.to-rem ( width, 300 );
        .to-rem ( margin, 10 0 0 3 );

        .to-rem ( font-size, 16 );
      }
    }

  }

  .cb-can-contact,
  label[for="cb-can-contact"] {
    position: fixed;
    z-index: -1000;
  }

  // button {
  //   .button ( #FA0, #E89403, #FFF, #E89403 );
  // }

  .form-section {
    .to-rem ( padding, 16 );
    .to-rem ( border, 1, solid #DDD );

    background-color: @color-white;

    &:not(:first-child) {
      .to-rem ( margin-top, 20 );
    }
  }

}

.cb-container {
  display: block;
  width: 100%;
  .to-rem ( padding, 10 );

  .to-rem ( border, 1, solid #CCC );
  background-color: #FFF;

  &:hover {
    background-color: #FFE5C5;
    border-color: #FA0;
  }

  input[type="checkbox"] {
    .to-rem ( margin-top, 0 );
  }
}

.grid-item {
  .cb-container {
    .to-rem ( margin-top, 4 );
  }
}

html.placeholder {
  label.hide-ph {
    // - HIDE PH MEANS WE'RE GOING TO HIDE IT IF PLACEHOLDERS ARE AVAILABLE
    // - THE EXCEPTION WILL BE FOR CONTROLS LIKE RADIOS AND CHECKBOXES
    display: none;
  }
}

.parsley-errors-list {
  display: block;

  .to-rem ( font-size, 12 );

  li {
    width: 100%;
    .to-rem ( padding, 12 );
    .to-rem ( margin, 0 );

    border-style: solid;
    .to-rem ( border-width, 1 );

    border-color: @form-error-border-colour;
    background-color: @form-error-bg-colour;
    color: @color-white;
  }
}

.single-parsley-error {
  width: 100%;
  .to-rem ( padding, 12 );
  .to-rem ( margin, 0 );

  border-style: solid;
  .to-rem ( border-width, 1 );

  border-color: @form-error-border-colour;
  background-color: @form-error-bg-colour;
  color: @color-white;

  .to-rem ( font-size, 12 );
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="tel"],
textarea:not(.auto-height) {
  .to-rem ( padding, 10 );
  border-style: solid;
  border-width: @clickables-border-width-rem;
  .to-rem ( margin, 4 0 0 0 );

  background-color: @color-white;
  border-color: @form-input-border-colour;
  border-bottom-color: lighten(@form-input-border-colour, 5%);
  color: @color-black;
}

select {
  .to-rem ( padding, 5 );
  .to-rem ( height, 34 );
  .to-rem ( border-radius, 4 );
  border-style: solid;
  border-width: @clickables-border-width-rem;
  display: block;
  .to-rem ( margin, 4 0 10 0 );

  background-color: @color-white;
  border-color: @form-input-border-colour;
  border-bottom-color: lighten(@form-input-border-colour, 5%);
  color: @color-black;
}

.custom-select-base {
  border-radius: 0;

  -webkit-appearance: none;
}

.custom-select-1 {
  .to-rem ( padding, 0 30 0 10 );
  .to-rem ( height, 41 );
  margin-top: 0;

  background-image: url('/assets/graphics/dropdown-arrow-666.png');
  background-repeat: no-repeat;
  background-position: ~"calc(100% - 10px)" center;

  .to-rem ( line-height, 21 );
}

input[disabled=""], input[disabled="disabled"],
select[disabled=""], select[disabled="disabled"],
textarea[disabled=""], textarea[disabled="disabled"] {
  &:not(.disabled-viewing) {
    background-color: #E9E9E9;
    color: #AAA;
  }
}

textarea {
  &:not(.auto-height) {
    width: 100%;
    .to-rem ( min-height, 200 );
  }
}

input.time-period {
  width: 5%;
  .to-rem ( margin-right, 4 );
}

select.time-period {
  .to-rem ( padding, 10 );
  .to-rem ( height, 41 );
}

.duration {
  input {
    .to-rem ( max-width, 70 );
    .to-rem ( height, 19 );
    box-sizing: content-box;
  }
  label {
    &:not(.hide-ph) {
      .to-rem ( margin-top, 4 );
      display: inline-block;
    }
  }
}

.table-form {
  .table-form-heading-label {
    .to-rem ( padding, 10 );
    .to-rem ( border-right-width, 1 );
    border-style: solid;
    border-color: #07B;
    background-color: #048;
    color: @color-white;

    text-align: center;
    vertical-align: top;
  }

  .table-form-heading-input {
    .to-rem ( padding, 0 );

    background-color: #059;

    input, textarea {
      margin: 0;
      width: 100%;
      border: none;

      background: none;
      color: @color-white;

      &:focus {
        background-color: #07B;
      }
    }
  }

  .table-form-label {
    .to-rem ( padding, 10 );
    .to-rem ( border-right-width, 1 );
    border-style: solid;
    border-color: #07B;
    background-color: #DFEFFF;
    color: #048;

    text-align: center;
    vertical-align: top;
  }

  .table-form-input {
    .to-rem ( padding, 0 );

    background-color: #E3F3FF;

    input, textarea {
      margin: 0;
      width: 100%;
      border: none;

      background: none;
      color: #048;

      &:focus {
        background-color: #F3FFFF;
      }
    }
  }
}

.form-hint {
  float: left;
  .to-rem ( padding, 10 );
  .to-rem ( border, 1, solid #226 );
  background-color: #0055a5;
  color: @color-white;

  .to-rem ( line-height, 30 );
}

.archive-status-filter {
  position: relative;
  .to-rem ( padding, 10 );
  .to-rem ( border-radius, 4 );
  .to-rem ( border-width, 1 );
  border-style: solid;

  border-color: #D5D5D5;
  background-color: @color-white;

  label {
    &:not(:first-child) {
      .to-rem ( margin-left, 15 );
    }
  }
}

/**
 * CUSTOM DATE RANGE CONTROL
 */
.date-range-label {
  .to-rem ( font-size, 18 );
  font-weight: bold;
}

.date-range-filter-container {
  .to-rem ( padding, 9 5 10 5 );
  .to-rem ( border-radius, 4 );
  .to-rem ( border, 1, solid #D5D5D5 );

  .date-range-filter {
    .to-rem ( padding, 6 );
    color: #AAA;
    .to-rem ( font-size, 13 );

    input {
      .to-rem ( width, 170 );
      border: none;

      color: #C00;

      font-weight: bold;
    }
  }
}

.tree-selected {
  .to-rem ( margin-top, 6 );
  padding: 0;
  .to-rem ( border-width, 1 );
  border-style: solid;
  border-color: #D5D5D5;

  div {
    float: left;
    .to-rem ( padding, 12 );
  }

  button {
    float: right;
    .to-rem ( padding, 12 );
    border: none;
    background-color: #7B7;
    color: #FFF;
  }
}