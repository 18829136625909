.privacy-policy {
  p {
    .to-rem ( margin, 16 0 );
    .to-rem ( line-height, 20 );
  }

  li {
    .to-rem ( margin, 8 18 );
    .to-rem ( line-height, 20 );
    list-style: initial;
  }
}
