.add-items-btns {
  li {
    width: 19.6%;
    display: inline-block;

    button {
      width: 100%;
      padding: 14px;
      height: 60px;
      font-size: 14px;
    }
  }
}

.DT-qualification-items-list {
  &.admin {
    tr[data-type="ol"] td {
      &:nth-child(1) {
        .to-rem ( border-left, 4, solid #09F );
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0099ff+0,0099ff+56,ffffff+56,ffffff+56 */
        background: #0099ff; /* Old browsers */
        background: -moz-linear-gradient(-90deg, #0099ff 0%, #0099ff 56%, #ffffff 56%, #ffffff 56%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-90deg, #0099ff 0%,#0099ff 56%,#ffffff 56%,#ffffff 56%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(90deg, #0099ff 0%,#0099ff 56%,#ffffff 56%,#ffffff 56%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0099ff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        color: #FFF;
      }
    }
  }

  tr[data-type="ol"] td.colour-coded-icon {
    color: #09F;
  }

  &.admin {
    tr[data-type="oc"] td {
      &:nth-child(1) {
        .to-rem ( border-left, 4, solid #6D6 );
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#66dd66+0,66dd66+56,ffffff+56 */
        background: #66dd66; /* Old browsers */
        background: -moz-linear-gradient(-90deg, #66dd66 0%, #66dd66 56%, #ffffff 56%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-90deg, #66dd66 0%,#66dd66 56%,#ffffff 56%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(90deg, #66dd66 0%,#66dd66 56%,#ffffff 56%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66dd66', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        color: #FFF;
      }
    }
  }

  tr[data-type="oc"] td.colour-coded-icon {
    color: #6D6;
  }

  &.admin {
    tr[data-type="ilt"] td {
      &:nth-child(1) {
        .to-rem ( border-left, 4, solid #F90 );
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff9900+0,ff9900+56,ffffff+56 */
        background: #ff9900; /* Old browsers */
        background: -moz-linear-gradient(-90deg, #ff9900 0%, #ff9900 56%, #ffffff 56%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-90deg, #ff9900 0%,#ff9900 56%,#ffffff 56%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(90deg, #ff9900 0%,#ff9900 56%,#ffffff 56%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9900', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        color: #FFF;
      }
    }
  }

  tr[data-type="ilt"] td.colour-coded-icon {
    color: #F90;
  }

  &.admin {
    tr[data-type="doc"] td {
      &:nth-child(1) {
        .to-rem ( border-left, 4, solid #C36 );
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cc3366+0,cc3366+56,ffffff+56 */
        background: #cc3366; /* Old browsers */
        background: -moz-linear-gradient(-90deg, #cc3366 0%, #cc3366 56%, #ffffff 56%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-90deg, #cc3366 0%,#cc3366 56%,#ffffff 56%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(90deg, #cc3366 0%,#cc3366 56%,#ffffff 56%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc3366', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        color: #FFF;
      }
    }
  }

  tr[data-type="doc"] td.colour-coded-icon {
    color: #C36;
  }

  &.admin {
    tr[data-type="asmnt"] td {
      &:nth-child(1) {
        .to-rem ( border-left, 4, solid #639 );
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#663399+0,663399+56,ffffff+56 */
        background: #663399; /* Old browsers */
        background: -moz-linear-gradient(-90deg, #663399 0%, #663399 56%, #ffffff 56%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-90deg, #663399 0%,#663399 56%,#ffffff 56%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(90deg, #663399 0%,#663399 56%,#ffffff 56%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#663399', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        color: #FFF;
      }
    }
  }

  tr[data-type="asmnt"] td.colour-coded-icon {
    color: #639;
  }

  td {
    &:nth-child(1) {
      cursor: move;
    }
  }
}

.qualification-details {
  td {
    .to-rem ( border-top, 1, solid #FFF );

    &:nth-child(1) {
      background-color: #EEE;
    }

    &:nth-child(2) {
      background-color: #F5F5F5
    }
  }
}

.add-manual-users-cb-host {
  .to-rem ( margin, 12 0 );
  .to-rem ( padding, 12 );
  .to-rem ( border, 1, solid #D5D5D5 );
  color: #888;

  span {
    position: relative;
    .to-rem ( top, 2 );
  }
}
