/* ========================================================================== *\
   Layout -> Boxes ($layout-boxes)
\* ========================================================================== */

.box {
  display: block;
  .to-rem(padding, @box-padding);
  background-color: @box-bg-color;

  > *:last-child {
    margin-bottom: 0;
  }
}

/**
 * Give `.box` a border style.
 */

.box-border {
  .to-rem(border, @box-border-width, @box-border-style @box-border-color);
}

/**
 * Give `.box` rounded edges.
 */

.box-round {
  .to-rem(border-radius, @box-border-radius);
}

/**
 * Remove padding from default `.box`.
 */

.box-flush {
  padding: 0;
}

/**
 * Give `.box` the appearance of being raised above its surrounding area.
 */

.box-raise {
  .to-rem(box-shadow, 0 0 @box-sink-box-shadow-blur, hsla(0, 0%, 0%, 0.15));
}

/**
 * Give `.box` the appearance of being sunken beneath its surrounding area.
 */

.box-sink {
  .to-rem(box-shadow, 0 0 @box-sink-box-shadow-blur, hsla(0, 0%, 0%, 0.15) inset);
}
