//   ______        __      ___       ___      ___   ______   ___        ______    ____  ____  ________   
//  /" _  "\      /""\    |"  |     |"  \    /"  | /" _  "\ |"  |      /    " \  ("  _||_ " ||"      "\  
// (: ( \___)    /    \   ||  |      \   \  //   |(: ( \___)||  |     // ____  \ |   (  ) : |(.  ___  :) 
//  \/ \        /' /\  \  |:  |      /\\  \/.    | \/ \     |:  |    /  /    ) :)(:  |  | . )|: \   ) || 
//  //  \ _    //  __'  \  \  |___  |: \.        | //  \ _   \  |___(: (____/ //  \\ \__/ // (| (___\ || 
// (:   _) \  /   /  \\  \( \_|:  \ |.  \    /:  |(:   _) \ ( \_|:  \\        /   /\\ __ //\ |:       :) 
//  \_______)(___/    \___)\_______)|___|\__/|___| \_______) \_______)\"_____/   (__________)(________/  
@cc-colour-xlight: #00BDFE;
@cc-colour-light: #00ADEE;
@cc-colour-dark : #0090C5;
&.cc {

  overflow: hidden;

  .to-rem ( max-height, 716 );

  border-color: @cc-colour-dark;

  .logo {
    .to-rem ( width, 90 );
    .to-rem ( margin, 18 8 );
  }

  .std-btn {
    position: absolute;
    .to-rem ( right, 10 );
    .to-rem ( bottom, 10 );
    .to-rem ( padding, 10 );
    .to-rem ( border-radius, 5 );

    cursor: pointer;

    color: @color-white;

    border: none;
    border-left-style: solid;
    .to-rem ( border-left-width, 2 );

    border-left-color: #247bfd;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2c2c2c+0,2c2c2c+50,191919+50 */
    background: #2c2c2c; /* Old browsers */
    background: -moz-linear-gradient(top,  #2c2c2c 0%, #2c2c2c 50%, #191919 50%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #2c2c2c 0%,#2c2c2c 50%,#191919 50%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #2c2c2c 0%,#2c2c2c 50%,#191919 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2c2c', endColorstr='#191919',GradientType=0 ); /* IE6-9 */

    .to-rem ( letter-spacing, 0.7 );
  }

  .athena-close-btn {
    .to-rem ( padding, 2 5 );
    .to-rem ( border-radius, 3 );
    float: right;
    border: none;

    background-color: transparent;
    color: @color-white;

    .to-rem ( font-size, 12 );
    text-transform: uppercase;

    &:hover {
      background-color: #861D00;
    }
  }

  #loading-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .to-rem ( padding, 20 );
    .to-rem ( border-radius, 20 );

    color: #FFF;
    background-color: rgba(0,0,0,0.6);
  }

  .athena-header {
    position: absolute;
    width: 100%;
    .to-rem ( height, 80 );
    .to-rem ( padding-left, 10 );

    background-color: @cc-colour-light;

    .heading {
      position: absolute;
      .to-rem ( top, 32 );
      .to-rem ( right, 10 );

      .to-rem ( font-size, 20 );
    }

    .sub-heading {
      position: absolute;
      .to-rem ( top, 56 );
      .to-rem ( right, 10 );

      .to-rem ( font-size, 14 );
    }

    .assessment-timer {
      display: none;
      position: absolute;
      .to-rem ( top, 58 );
      .to-rem ( right, 5 );
      color: #FFF;
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    .to-rem ( height, 60 );
    .to-rem ( padding, 10 );
    z-index: @Z-athena-footer;

    background-color: @cc-colour-light;

    .menu-btn {
      position: absolute;
      .to-rem ( top, 15 );
      .to-rem ( left, 15 );
      opacity: 1;
      .to-rem ( padding, 8 );
      .to-rem ( border-radius, 7 );
      border: none;

      background-color: @cc-colour-dark;
      color: #FFF;

      box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
      cursor: pointer;

      &:hover {
        background-color: @cc-colour-xlight;
        box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
      }

      &.disabled {
        opacity: .6;
      }
    }

    .full-screen-btn {
      position: absolute;
      .to-rem ( top, 17 );
      .to-rem ( right, 203 );
      .to-rem ( padding, 8 10 7 10 );
      .to-rem ( border-radius, 7 );
      border: none;

      background-color: @cc-colour-dark;
      color: #FFF;

      box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
      cursor: pointer;

      &:hover {
        background-color: @cc-colour-xlight;
        box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
      }
    }

    .back-btn {
      position: absolute;
      .to-rem ( top, 15 );
      .to-rem ( right, 145 );
      opacity: 1;
      .to-rem ( padding, 8 );
      .to-rem ( border-radius, 7 );
      border: none;

      background-color: @cc-colour-dark;
      color: #FFF;

      box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
      cursor: pointer;

      &:hover {
        background-color: @cc-colour-xlight;
        box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
      }

      &.disabled {
        opacity: .6;
      }
    }

    .replay-btn {
      position: absolute;
      .to-rem ( top, 15 );
      .to-rem ( right, 72 );
      opacity: 1;
      .to-rem ( padding, 8 );
      .to-rem ( border-radius, 7 );
      border: none;

      background-color: @cc-colour-dark;
      color: #FFF;

      box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
      cursor: pointer;

      &:hover {
        background-color: @cc-colour-xlight;
        box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
      }

      &.disabled {
        opacity: .6;
      }
    }

    .next-btn {
      position: absolute;
      .to-rem ( top, 15 );
      .to-rem ( right, 15 );
      opacity: 1;
      .to-rem ( padding, 8 );
      .to-rem ( border-radius, 7 );
      border: none;

      background-color: @cc-colour-dark;
      color: #FFF;

      box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
      cursor: pointer;

      &:hover {
        background-color: @cc-colour-xlight;
        box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
      }

      &.disabled {
        opacity: .6;
      }
    }
  }

  *[athena-tooltip]:hover .athena-tooltip {
    opacity: 1;
  }

  .athena-tooltip {
    display: inline;
    position: absolute;
    bottom: 125%;
    .to-rem ( padding, 4 );
    .to-rem ( border-radius, 8 );
    .to-rem ( border-width, 1 1 3 1 );
    border-style: solid;
    border-color: @cc-colour-xlight;
    background-color: @cc-colour-dark;
    color: #ffffff;
    .to-rem ( font-size, 12 );
    z-index: 100;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.1);
    opacity: 0;

    transition-property: opacity;
    transition-duration: .3s;

    &::before {
      content: '';
      position: absolute;
      z-index: 10;
      .to-rem ( bottom, -17 );
      right: ~"calc(50% - 7px)";
      .to-rem ( height, 0 );
      .to-rem ( border-width, 7 );
      border-style: solid;
      border-color: @cc-colour-xlight transparent transparent transparent;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 5;
      .to-rem ( bottom, -17 );
      right: ~"calc(50% - 7px)";
      .to-rem ( height, 0 );
      .to-rem ( border-width, 7 );
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
    }
  }

  /* TOOLTIPS SPECS START */
  
  .athena-tooltip {
    //left: NEEDS TO BE CENTRAL TO ITS PARENT;// - TAKEN CARE OF IN JS
    .to-rem ( width, 112 );
  }

  /* TOOLTIPS SPECS START */

  .click-next-to-continue {
    position: absolute;
    .to-rem ( bottom, -90 );
    .to-rem ( right, 20 );
    .to-rem ( padding, 12 );

    .to-rem ( border-radius, 10 );
    .to-rem ( border, 2, solid @cc-colour-xlight );
    .to-rem ( border-bottom, 5, solid @cc-colour-xlight );

    background-color: @cc-colour-dark;
    color: @color-white;

    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

    &::before {
      content: '';
      position: absolute;
      z-index: 10;
      .to-rem ( bottom, -17 );
      .to-rem ( right, 25 );
      .to-rem ( height, 0 );
      .to-rem ( border-width, 7 );
      border-style: solid;
      border-color: @cc-colour-xlight transparent transparent transparent;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 5;
      .to-rem ( bottom, -17 );
      .to-rem ( right, 25 );
      .to-rem ( height, 0 );
      .to-rem ( border-width, 7 );
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2) transparent transparent transparent;
    }

    &.in-front {
      z-index: @Z-athena-click-next-in-front;
    }

    &.behind {
      z-index: @Z-athena-click-next-behind;
    }

    .click-next-text {
      -webkit-user-select: none; /* Chrome/Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+ */

      /* Rules below not implemented in browsers yet */
      -o-user-select: none;
      user-select: none;
    }
  }

  .athena-content {
    position: absolute;
    display: none;
    opacity: 0;
    .to-rem ( width, 900 );
    height: 100%;
    .to-rem ( top, 80 );
    .to-rem ( margin, 30 20 );

    .slide-title {
      position: absolute;
      top: 40%;
      width: 100%;
      text-align: center;
    }
  }

  .athena-menu {
    position: absolute;
    .to-rem ( top, 80 );
    .to-rem ( margin-top, 30 );

    .menu-list-container {
      .to-rem ( max-width, 900 );
      .to-rem ( height, 500 );
      margin: 0 auto;
      overflow-y: scroll;
    }

    li.menu-item {
      list-style: none;
      float: left;
      .to-rem ( margin-bottom, 16 );
    }

    li.menu-item:last-child {
      margin-bottom: 0;
    }

    .menu-item-text-bar {
      float: left;
      .to-rem ( width, 521 );
      .to-rem ( height, 42 );
      .to-rem ( padding, 13 0 0 18 );
      .to-rem ( border-radius, 20 0 0 20 );
      background-color: @cc-colour-light;
      background-position: 0 0;
      background-repeat: no-repeat;
      color: #FFF;
      font-family: calibri;

      .menu-item-text {
        font-family: "PT Sans";
        white-space: nowrap;
      }

      .athena-menu-icon {
        position: relative;
        float: right;
        .to-rem ( margin-top, 1 );
        .to-rem ( margin-right, 16 );
        color: @color-white;
        .to-rem ( font-size, 14 );

        &.fa-ellipsis-h {
          .to-rem ( top, 1 );
        }
      }
    }

    .menu-item-start-btn {
      position: relative;
      .to-rem ( left, -1 );
      float: left;
      .to-rem ( width, 42 );
      .to-rem ( height, 42 );
      .to-rem ( padding, 14 );
      .to-rem ( border-radius, 0 20 20 0 );
      background-color: @cc-colour-dark;
      color: #FFF;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;

      &:after {
        content: '\f061';
        color: #FFF;
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
      }
    }

    .menu-item-start-btn.mouse-over {
      background-color: @cc-colour-xlight;
    }
  }

  .mobile-video-play-btn {
    position: absolute;
    .to-rem ( width, 212 );
    .to-rem ( height, 212 );
    top: 50%;
    left: 50%;
    .to-rem ( margin-left, -106 );
    .to-rem ( margin-top, -106 );

    background-image: url('/assets/graphics/athena-skins/ilearn/BigPlayBtn.png');
  }

  .summary-body {
    .percentage-bar-host {

      .summary-your-score {
        .tc;
        .mt;
        .mb-;
        text-align: center;
        .to-rem ( margin-top, 16 );
        .to-rem ( margin-bottom, 8 );
      }

      .percentage-bar {
        position: relative;
        width: 100%;
        .to-rem ( height, 44 );
        .percentage-fill {
          position: absolute;
          height: 100%;
        }

        .percentage-text {
          position: absolute;
          width: 100%;
          text-align: center;
          .to-rem ( padding, 9 );
        }
      }
    }

    .summary-move-on-message {
      // .to-rem ( margin-top, 8 );
      // text-align: center;
    }
  }
}