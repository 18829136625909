.breadcrumbs-container {
  .to-rem ( padding, 10 );
  .to-rem ( border-bottom, 3, solid #F2F2F2 );
  background-color: #FFFFFF;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1);

  li {
    margin-left: 0;
    display: inline-block;

    &:hover {
      text-decoration: underline !important;
    }
  }
}