// - CSS RESET
@import url('reset.less');

// - LOAD CARDINAL
@import url('../3plib/cardinal/cardinal.less');

// - LOAD DATATABLES
@import url('../3plib/data-tables/datatables.less');

// - FONTAWESOME
// @import url('../../bower_components/font-awesome/less/font-awesome.less');

@import url('https://fonts.googleapis.com/css?family=Oxygen:400,700');
// @import url('https://fonts.googleapis.com/css?family=Teko');

// - CORE
@import url("core/border-radius.less");
@import url("core/mixins.less");
@import url("core/header.less");
@import url("core/main.less");
@import url("core/tables.less");
@import url("core/forms.less");
@import url("core/widgets.less");
@import url("core/ext.less");
@import url("core/tree-selector.less");

// - THEME
@theme : "default";

// - THEME CORE
@import url("themes/@{theme}/variables.less");
@import url("themes/@{theme}/main.less");
@import url("themes/@{theme}/header.less");
@import url("themes/@{theme}/forms.less");
@import url("themes/@{theme}/tables.less");
@import url("themes/@{theme}/cms.less");
@import url("themes/@{theme}/widgets.less");
@import url("themes/@{theme}/tab-nav.less");
@import url("themes/@{theme}/file-viewer.less");
@import url("themes/@{theme}/repository.less");//integrate this into file-viewer.less

// - THEME PAGES
@import url("themes/@{theme}/breadcrumbs.less");
@import url("themes/@{theme}/pages/register.less");
@import url("themes/@{theme}/pages/sign-in.less");
@import url("themes/@{theme}/pages/admin/dashboard.less");
@import url("themes/@{theme}/pages/admin/ilt-courses.less");
@import url("themes/@{theme}/pages/admin/ilt-sessions.less");
@import url("themes/@{theme}/pages/admin/assessment-builder.less");
@import url("themes/@{theme}/pages/admin/reports.less");
@import url("themes/@{theme}/pages/admin/users.less");
@import url("themes/@{theme}/pages/admin/settings.less");
@import url("themes/@{theme}/pages/admin/permissions.less");
@import url("themes/@{theme}/pages/admin/qualifications.less");
@import url("themes/@{theme}/pages/admin/roles.less");
@import url("themes/@{theme}/pages/admin/institutions.less");
@import url("themes/@{theme}/pages/occupational-competence.less");
@import url("themes/@{theme}/pages/elearning.less");
@import url("themes/@{theme}/pages/forum.less");
@import url("themes/@{theme}/pages/news.less");
@import url("themes/@{theme}/pages/help.less");
@import url("themes/@{theme}/pages/tour-guide.less");
@import url("themes/@{theme}/pages/faqs.less");
@import url("themes/@{theme}/pages/glossary.less");
@import url("themes/@{theme}/pages/assessment-player.less");
@import url("themes/@{theme}/athena.less");
@import url("themes/@{theme}/pages/home-page.less");
@import url("themes/@{theme}/pages/my-account.less");
@import url("themes/@{theme}/pages/messenger.less");
@import url("themes/@{theme}/pages/privacy-policy.less");
@import url("themes/@{theme}/pages/qualification.less");
@import url("themes/@{theme}/pages/terms.less");
@import url("themes/@{theme}/pages/sitemap.less");
@import url("themes/@{theme}/pages/contact-us.less");
@import url("themes/@{theme}/pages/itr.less");
@import url("themes/@{theme}/pages/cpd.less");
@import url("themes/@{theme}/pages/forgotten-password.less");
@import url("bug-tracker.less");

// - JQUERY UI THEME
@import url("../3plib/jquery-ui/themes/custom/jquery-ui.min.less");
@import url("../3plib/jquery-ui/themes/custom/jquery-ui.theme.less");
@import url("../3plib/timepicker/jquery-ui-timepicker-addon.less");
