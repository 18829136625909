// - STOP BODY BEING SCROLLABLE FOR MODALS
html, body {
  height: 100%;
}

.overlay{
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  // background-color: rgba(0, 0, 0, 0.8);
  //
  // .overlay-content {
  //     height: 100%;
  //     overflow: scroll;
  // }
}

.background-content{
  height: 100%;
  overflow: auto;
}
// - END STOP BODY BEING SCROLLABLE FOR MODALS

body {
  .width-auto {
    width: auto;
  }

  &.no-select {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */

    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
  }

  .background-content > main {
    .to-rem ( padding, 30 );

    .inner {
      .to-rem ( max-width, 978 );
    }
  }

  *:focus {
    outline: none !important;
  }

  .generic-blackout {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1100;

    width: 100%;
    height: 100%;

    background-color: @color-black;
    opacity: .5;
  }

  .overlays {
    display: none;
    position: fixed;
    z-index: @Z-overlays;
    width: 100%;
    height: 100%;

    .blackout {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1100;

      width: 100%;
      height: 100%;

      background-color: @color-black;
      opacity: .5;
    }

    .popup-flex {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: flex-end;
      justify-content: center;

      .screen-md-min({
        align-items: center;
      });

      .popup {
        display: none;
        position: relative;
        top: 100%;
        z-index: 1200;

        width: 100%;
        height: 100%;
        .to-rem ( max-width, 1000 );
        .to-rem ( max-height, 90% );
        .to-rem ( border, 3, solid #222 );

        header {
          display: block;
          float: left;
          width: 100%;
          .to-rem ( padding, 10 );

          background-color: #DDD;
        }

        main.popup-content {
          position: absolute;
          float: left;
          .to-rem ( padding, 10 );
          overflow: scroll;
          .to-rem ( top, 45 );
          .to-rem ( bottom, 0 );
        }

        .popup-toolbar {
          display: none;
          float: left;
          width: 100%;
          .to-rem ( margin, 0 );
          .to-rem ( padding, 10 );

          background-color: @color-white;

          .popup-toolbar-btn {
            .to-rem ( padding, 6 );
            .to-rem ( border, 0 );

            background: transparent;
            color: #999;

            text-shadow: none;

            &:hover {
              color: @color-black;
            }

            i.fa {
              .to-rem ( font-size, 18 );
            }
          }
        }

        footer {
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          .to-rem ( height, 32 );
          .to-rem ( padding, 10 );

          .to-rem ( border-top, 1, solid #d6d6d6 );

          background-color: #DDD;
        }
      }

    }

    .athena-flex {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: flex-end;
      justify-content: center;
      position: relative;
      z-index: @Z-athena-flex;

      .screen-md-min({
        align-items: center;
      });

      .athena-in-popup {
        display: none;
        position: relative;
        z-index: 1200;
        top: 100%;

        width: 100%;
        height: 100%;
        .to-rem ( max-width, 956 );
        //.to-rem ( max-height, 716 );//in skin
        //.to-rem ( border, 3, solid #222 );
        .to-rem ( border-width, 8 );
        border-style: solid;

        background-color: @color-white;
      }

    }
  }

  .hidden {
    visibility: hidden;
  }

  .location-selector {
    width: 100%;
    .to-rem ( height, 300 );
    .to-rem ( padding, 5 20 );

    optgroup,
    option {
      .to-rem ( padding, 10 0 );
      .to-rem ( border-top, 1, solid #F7F7F7 );
    }

    optgroup {
      color: #64AED8;

      .to-rem ( font-size, 17 );
      font-weight: bold;
    }

    option {
      color: @color-black;

      .to-rem ( font-size, 15 );
      font-weight: normal;

      &:first-child {
        .to-rem ( margin-top, 10 );
        font-weight: bold;
      }
    }
  }
}

// @GLOSSARY
.alphanum-nav {
  .to-rem ( margin, 0 auto );
  .to-rem ( width, 420 );

  a {
    text-decoration: none;
  }

  .alphanum-nav-showing {
    .to-rem ( padding, 2 );

    background-color: #62BAD7;
    color: @color-white;
  }
}

.photo-placeholder {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  .to-rem ( padding, 2 );
  border-style: solid;
  .to-rem ( border-width, 2 );
  .to-rem ( border-radius, 3 );

  border-color: #CCC;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9f9f9+0,f9f9f9+0,e2e2e2+100 */
  background: #f9f9f9; /* Old browsers */
  background: -moz-linear-gradient(top,  #f9f9f9 0%, #f9f9f9 0%, #e2e2e2 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #f9f9f9 0%,#f9f9f9 0%,#e2e2e2 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #f9f9f9 0%,#f9f9f9 0%,#e2e2e2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-size: cover;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#e2e2e2',GradientType=0 ); /* IE6-9 */
  color: #CCC;

  .to-rem ( font-size, 48 );
}

.image-upload-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  > * {
    .to-rem ( margin, 5 0 );
  }

  > button {
    .p;
    .pt-;
    .pb-;

    .fz\+\+;
  }

  > .or {
    .to-rem ( padding, 15 );

    color: #CCC;

    font-style: italic;
    .to-rem ( font-size, 20 );
  }
}

.inline-upload-photo {
  width: 100%;
  height: 100%;
  .to-rem ( margin-left, -187 );
  .to-rem ( padding-left, 190 );

  .upload_form {
    height: 100%;

    label[for="file_upload"] {
      position: relative;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;

      .to-rem ( margin-top, 0 );
      width: 50%;
      height: 100%;
    }

    input[type="file"] {
      width: 50%;
      height: 100%;
    }

    .upload-progress-host {
      float: left;
      width: 48%;
      margin-left: 2%;

      .file_to_upload {
        .mt0;
      }
    }
  }
}

.photo-capture {
  width: 100%;
  height: 100%;
  .to-rem ( margin-left, -170 );
  .to-rem ( padding-left, 190 );

  .video-cuttoff {
    float: left;
    overflow: hidden;
    .to-rem ( width, 170 );
    .to-rem ( height, 186 );

    .photo-capture-video {
      .to-rem ( max-width, 250 );
      .to-rem ( width, 250 );
      .to-rem ( margin-left, -40 );
    }
  }

  canvas.photo-capture-canvas {
    float: left;
    display: none;
    .to-rem ( width, 250 );
    .to-rem ( height, 186 );
  }

  .profile-photo-capture-img {
    float: left;
    .to-rem ( width, 170 );
  }

  .photo-camera-controls {
    float: left;
    .to-rem ( padding, 15 0 );
    .to-rem ( margin-right, 10 );
    .to-rem ( border-radius, 0 8 8 0 );
    .to-rem ( border-width, 1 );
    border-style: solid;

    border-color: #DDD;
    background-color: #EEE;

    .photo-camera-control-btn {
      .to-rem ( width, 80 );
      .to-rem ( margin-bottom, 19 );
      border: none;

      background-color: transparent;

      .to-rem ( font-size, 34 );

      &:last-child {
        .to-rem ( margin-bottom, 1 );
      }

      &.active {
        color: #444;

        &:hover {
          color: #7B7;
        }
      }

      &.inactive {
        color: #CCC;

        &:hover {
          cursor: no-drop;
        }
      }
    }
  }
}

// - FONT STYLING
.em { font-style: italic !important; }

// - TEXT COLOURS
.grey { color: #666 !important; }
.l-grey { color: #999 !important; }
.ll-grey { color: #CCC !important; }

// - BG COLOURS
.bg-grey,
.bg-grey td { background-color: #666 !important; }
.bg-l-grey,
.bg-l-grey td { background-color: #999 !important; }
.bg-ll-grey,
.bg-ll-grey td { background-color: #CCC !important; }

.bg-666,
.bg-666 td { background-color: #666 !important; }
.bg-999,
.bg-999 td { background-color: #999 !important; }
.bg-CCC,
.bg-CCC td { background-color: #CCC !important; }
.bg-DDD,
.bg-DDD td { background-color: #DDD !important; }
.bg-EEE,
.bg-EEE td { background-color: #EEE !important; }
.bg-FFF,
.bg-FFF td { background-color: #FFF !important; }

.no-hyphenation {
  hyphens: none !important;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

.notification {
  .to-rem ( padding, 8 );
  .to-rem ( margin-bottom, 12 );
  .to-rem ( border-radius, 4 );
  
  text-align: center;

  &.red-notification {
    background-color: #C00;
    color: #FFF;
  }
}

.warning-text {
  .to-rem ( padding, 5 16 3 16 );
  .to-rem ( border-radius, 5 );
  .to-rem ( border-width, 1 );
  border-style: solid;
  border-color: #CC6767;
  background-color: #FCC;
  color: #B13E3E;

  a {
    color: #38849E;
  }
}

.scheduled-maintenance-soon {
  .to-rem ( padding, 20 );
  background-color: #FB0;
  color: #FFF;
  .to-rem ( font-size, 18 );
  font-weight: bold;
  text-align: center;
}