table {
  &.fancytree-ext-table {
    /* flex: 1; */
    width: 100%;
    border-collapse: collapse;
    border: 1px dotted #f4f4f8;
    caption-side: bottom;
    
    thead {
      th {
        &.parent-path {
          text-align: left;
        }
      }
    }
    
    tbody {
      tr {
        border-top: 1px solid #E4E4E4;
        
        &.fancytree-hide {
          display: table-row;
        }
        
        &:nth-child(even) {
          background-color: #FAFAFA;
          
          td {
            background-color: #FAFAFA;
          }
        }
      }
    }
    
    td {
      &nth-child(2) {
        width: 50px;
      }
      
      &nth-child(4) {
        text-align: right;
      }
      
      &nth-child(5) {
        text-align: center;
      }
    }
    
    &.fancytree-ext-ariagrid {
      &.fancytree-cell-mode {
        &.fancytree-cell-nav-mode {
          > tbody {
            > tr {
              > td {
                &.fancytree-active-cell {
                  background-color: #EEE !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

div {
  &.fancytree-grid-container {
    /* Use whole parent heigt*/
    flex: 1;
    /* Stretch embedded table */
    display: flex;
    flex-direction: column;
    min-height: 30px;
    /*  */
    padding: 0;
    border: 1px solid #E4E4E4;
    /* The div should not be stretched by the content rows, because we want to
     adust the row count from div height instead: */
    overflow-y: hidden;
    
    > table.fancytree-ext-grid {
      flex: 1;
      width: 100%;
      border: 0;
      margin: 1px 0 1px 0;  /* 1px above and below. */
    }
  }
}

.course-stds-search {
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #DDD;
}

.selected-item-info {
  .to-rem ( min-height, 5 );
  .to-rem ( padding, 10 20 );
  .to-rem ( border-width, 0 1 1 1 );
  border-style: solid;
  border-color: #E4E4E4;

  .asset-exposures {
    tr {
      .to-rem ( border-bottom, 1, solid #D8D8D8 );

      &:first-child {
        .to-rem ( border-top, 1, solid #D8D8D8 );
      }

      td {
        &:nth-child(1) {
          background-color: #F4F4F4;

          font-weight: bold;
        }
      }

      td {
        &:nth-child(2) {
          background-color: #FFF;
        }
      }
    }
  }
}

.section-full-screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
}

.node-from-ext,
tr[data-source="node-from-ext"] {
  td {
    span {
      &.fancytree-node {
        span {
          &.fancytree-title {
            color: #CCC;
          }
        }
      }
    }
  }
}