.percentage-bar {

  position: relative;
  width: 100%;
  .to-rem ( height, 24 );

  .percentage-fill {
    float: left;
  }

  .percentage-text {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

.ui-datepicker {
  .ui-datepicker-buttonpane {
    button {
      &.ui-datepicker-current {
        display: none;
      }
    }
  }
}

// - TOOLTIP
.tooltip {
  position: relative;

  .tooltip-box {
    position: absolute;
    .to-rem ( left, -19 );
    .to-rem ( top, 60 );
    z-index: 1000;
    .to-rem ( padding, 17 );
    .to-rem ( border, 3, solid #F90 );
    background: #FFF;
    color: #333;
    opacity: 0;
    visibility: hidden;

    box-shadow: 9px 9px 5px 2px rgba(0,0,0,0.2);
    hyphens: none;

    transition-property: opacity, top, visibility;
    transition-duration: 0.4s, 0.4s, 0s;
    transition-delay: 0s, 0s, 0.4s;

    * {
      hyphens: none;
    }

    &::after {
      content: '';
      position: absolute;
      .to-rem ( top, -14 );
      .to-rem ( left, 14 );
      .to-rem ( border-width, 8 );
      border-style: solid;
      border-color: transparent transparent #FFF transparent;
    }

    &::before {
      content: '';
      position: absolute;
      .to-rem ( top, -20 );
      .to-rem ( left, 12 );
      .to-rem ( border-width, 10 );
      border-style: solid;
      border-color: transparent transparent #F90 transparent;
    }

    p {
      .to-rem ( line-height, 16 );
    }

    ul {
      margin-bottom: 0;
      list-style: disc;
    }
  }

  .pfd-cb:checked {
    & + .tooltip-assets {
      .tooltip-box {
        .to-rem ( top, 30 );
        .to-rem ( left, -19 );
        color: #333;
        opacity: 1;
        visibility: visible;
        transition-delay: 0s, 0s, 0s;
      }

      .fa-question-circle {
        display: none;
      }

      .fa-times {
        display: block;
      }
    }
  }

  .pfd-cb:not(:checked) {
    & + .tooltip-assets {
      .tooltip-box {
        .to-rem ( top, 60 );
        .to-rem ( left, -19 );
        opacity: 0;
        visibility: hidden;
        transition-delay: 0s, 0s, 0.4s;
      }

      .fa-question-circle {
        display: block;
      }

      .fa-times {
        display: none;
      }
    }
  }

  .fa-question-circle {
    color: #09F;
    .to-rem ( font-size, 18 );
  }

  .fa-times {
    color: #D22;
    .to-rem ( font-size, 18 );
  }
}