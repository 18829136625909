/* ========================================================================== *\
   Base -> Horizontal Rules ($base-horizontal-rules)
\* ========================================================================== */

hr {
  display: block;
  padding: 0;
  border: 0;
  .to-rem(border-top, @hr-border-width, @hr-border-style @hr-border-color);
}
